<template>
  <component
    :is="slope.slopeType"
    :slope="slope"
    :slopeFormStore="slope.slopeForm"
    @showDeleteConfirmationModal="showDeleteConfirmationModal()"
    @updateSlopeForm="updateSlopeForm"
    v-if="slope.materialSize.width"
  ></component>
  <!-- v-show="slope.materialSize.width !== 0"-->
</template>

<script>
import mainSlope from './MainSlope.vue'
import contrSlope from './ContrSlope.vue'

export default {
  props: {
    slope: Object
  },
  data() {
    return {}
  },
  components: {
    mainSlope,
    contrSlope
  },
  methods: {
    updateSlopeForm(val) {
      this.$store.commit('updateSlopeForm', val)
    },
    showDeleteConfirmationModal() {
      this.$emit('showDeleteConfirmationModal')
    }
  },
  computed: {}
}
</script>
