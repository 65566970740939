<template>
  <div class="fasteners-calculation">
    <div class="fasteners-calculation__header">
      <div class="title">{{ $t('message.sectorFastener.title') }}</div>
      <switcher
        :calculation-need="fastenerCalculationNeed"
        @updateSwitchingValue="updateFastenerCalculationNeed"
      />
    </div>
    <div class="fasteners-calculation__body" v-if="fastenerCalculationNeed">
      <div class="fasteners-calculation__body-top">
        <base-type-switcher :current-basement="currentBasement" @updateBasement="updateBasement" />
        <insulation-thickness :thickness="thickness" @updateThickness="updateInsulationThickness" />
      </div>
      <div class="fasteners-calculation__body-bottom">
        <fastener-selection
          :current-fastener="currentFastener"
          :current-basement="currentBasement"
          @updateFastener="updateFastener"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Switcher from '@/components/dump/sector_fasteners/Switcher'
import BaseTypeSwitcher from '@/components/dump/sector_fasteners/BaseTypeSwitcher'
import InsulationThickness from '@/components/dump/sector_fasteners/InsulationThickness'
import FastenerSelection from '@/components/dump/sector_fasteners/FastenerSelection'
export default {
  props: {
    fastenerCalculationNeed: {
      type: Boolean,
      required: true
    },
    currentBasement: {
      type: Number
    },
    thickness: {
      type: Number,
      default: 0,
      required: true
    },
    currentFastener: {
      type: Number,
      required: true
    }
  },
  name: 'SectorFasteners',
  methods: {
    updateFastenerCalculationNeed(val) {
      this.$emit('updateFastenerCalculationNeed', val)
    },
    updateInsulationThickness(val) {
      this.$emit('updateInsulationThickness', val)
    },
    updateFastener(val) {
      this.$emit('updateFastener', val)
    },
    updateBasement(val) {
      this.$emit('updateBasement', val)
    }
  },
  components: { FastenerSelection, InsulationThickness, BaseTypeSwitcher, Switcher }
}
</script>

<style scoped lang="sass">
.fasteners-calculation
  background: $white
  margin: rem(17) rem(-15) rem(51)
  +media((padding: (320: rem(25), 992: rem(40))))
  &__header
    display: flex
    align-items: center
    justify-content: space-between
    .title
      font-style: normal
      font-weight: bold
      +media((font-size: (320: rem(14), 992: rem(18))))
      +media((line-height: (320: rem(17), 992: rem(22))))
      color: $black
  &__body
    margin-top: rem(40)
    &-top
      display: flex
      +media((flex-direction: (320: column, 768: row)))
      .base-types
        +media((margin: (320: 0 0 rem(32) 0, 768: 0 rem(78) 0 0)))
</style>
