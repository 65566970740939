<template>
  <div class="similar__box">
    <div class="similar__box-title slopePage__subtitle" :class="{ 'title--inline': isInline }">
      <span class="text">
        <slot></slot>
      </span>
      <slot v-if="!isInline" name="tooltip"></slot>
    </div>
    <plus-minus-input
      :isInline="isInline"
      :value="count"
      :step="1"
      :min="min"
      @updateValue="updateCount"
    />
    <slot v-if="isInline" name="tooltip"></slot>
  </div>
</template>

<script>
import PlusMinusInput from '@/components/dump/PlusMinusInput'
export default {
  props: {
    count: {
      type: Number,
      default: 0,
      required: true
    },
    min: Number,
    isInline: Boolean
  },
  name: 'SectorCountsControls',
  components: { PlusMinusInput },
  methods: {
    updateCount(val) {
      this.$emit('updateCount', val)
    }
  }
}
</script>

<style scoped lang="sass">
.similar
  &__box
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    +media((padding: (320: rem(15) rem(25), 992: rem(42) rem(40))))
    &:not(:last-child)
      box-shadow: inset 0 rem(-1) 0 $ec
    &-title
      text-align: left
      margin: 0
      line-height: rem(22)
      padding: 0
      display: flex
      +media((align-items: (320: flex-start, 375: center)))
      +media((flex-direction: (320: column, 375: row)))
      +media((margin-right: (320: rem(12), 576: 0)))
      .text
        +media((margin-right: (375: rem(12))))
.similar__box
  .title--inline
    margin-right: 0
    .text
      width: rem(100)
</style>
