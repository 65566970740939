import { render, staticRenderFns } from "./RoofOptionsSlope.vue?vue&type=template&id=308a0ca2&scoped=true"
import script from "./RoofOptionsSlope.vue?vue&type=script&lang=js"
export * from "./RoofOptionsSlope.vue?vue&type=script&lang=js"
import style0 from "./RoofOptionsSlope.vue?vue&type=style&index=0&id=308a0ca2&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308a0ca2",
  null
  
)

export default component.exports