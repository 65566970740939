<template>
  <div class="bottom-navigation">
    <simple-btn color="grey" @btnClicked="navigate">
      <template #icon>
        <span class="icon">
          <img src="@/assets/img/arrow-back--black.svg" alt="←" />
        </span>
      </template>
      {{ btnText }}
    </simple-btn>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import SimpleBtn from '@/components/dump/simpleBtn'
export default {
  props: {
    slopeId: {
      type: Number,
      default: 0,
      required: true
    },
    currentSector: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  name: 'BottomPageNavigation',
  methods: {
    ...mapMutations({
      removeSlopeMaterial: 'removeSlopeMaterial',
      setSlopeToDefault: 'setSlopeToDefault'
    }),
    navigate() {
      const { isMaterialSelected, removeSlopeMaterial, slopeId, setSlopeToDefault } = this
      isMaterialSelected ? removeSlopeMaterial(slopeId) : setSlopeToDefault(slopeId)
    }
  },
  components: { SimpleBtn },
  computed: {
    isMaterialSelected() {
      return !!this.currentSector.materialSize.width
    },
    btnText() {
      return this.isMaterialSelected
        ? this.$t('message.wizard.bottomNavMaterial')
        : this.$t('message.wizard.bottomNavSlope')
    }
  }
}
</script>

<style scoped lang="sass">
.bottom-navigation
  +media((padding: (320: 0 rem(25), 960: 0)))
  +media((margin: (320: rem(34) auto, 768: rem(51) auto)))
  display: flex
  justify-content: flex-start
  max-width: rem(960)
</style>
