<template>
  <section class="selectionPage">
    <Nav ref="Nav" :slopeType="slopeFromState.slopeType" @addFromNav="save" />
    <div class="container">
      <div class="slopePage__title" v-if="slopeFromState.slopeType === ''">
        {{ $t('message.wizard.title') }}
      </div>
      <div class="slope__selection" v-if="slopeFromState.slopeType === ''">
        <label
          v-for="item in slopeTypes"
          :for="item.value"
          class="slope__selection-item"
          :key="item.id"
        >
          <input
            type="radio"
            :id="item.value"
            :value="item.value"
            v-model="slopeFromState.slopeType"
            @change="updateSlopeType"
          />
          <span class="slope__selection-item__img">
            <img :src="item.src" :alt="item.title" />
          </span>
          <span class="slope__selection-item__title">
            {{ item.title }}
          </span>
          <span class="slope__selection-item__text">
            {{ item.text }}
          </span>
        </label>
      </div>
    </div>
    <Materials :type="slopeFromState.slopeType" />
    <SlopeView
      :slope="slopeFromState"
      @showDeleteConfirmationModal="showDeleteConfirmationModal()"
    />
    <transition name="fading">
      <DeleteConfirmation
        v-if="isShowDeleteConfirmation"
        @confirm="deleteSlope()"
        @cancel="isShowDeleteConfirmation = !isShowDeleteConfirmation"
      />
    </transition>
    <bottom-page-navigation
      v-if="slopeFromState.slopeType !== ''"
      :slope-id="slopeId"
      :current-sector="slopeFromState"
    />
    <div class="container">
      <div class="buttons__wrapper" v-show="slopeFromState.materialSize.width !== 0">
        <div class="buttons__wrapper-left">
          <div class="buttons">
            <button @click="save" class="save__btn">
              <img src="@/assets/img/plus-big.svg" alt="+" />
              {{ $t('message.wizard.save') }}
            </button>
            <button
              @click="isShowDeleteConfirmation = !isShowDeleteConfirmation"
              class="delete__btn"
              v-if="this.allSlopes.length !== 1"
            >
              <svg
                width="19"
                height="25"
                viewBox="0 0 19 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 2.91667L18 5.29333M5.73105 3.60667L7.89615 1L12.1461 1.61333L13.349
            4.60333M12.467 12.1933L12.2264 19.5533M6.61321 12.1933L6.85377 19.63M1.56128
            7.74667H17.4386L16.6368 21.7C16.5566 22.9267 15.5141 24 14.2311 24H4.76883C3.40562 24
            2.44336 22.9267 2.36317 21.7L1.56128 7.74667Z"
                  stroke="#999999"
                  stroke-width="2"
                  stroke-miterlimit="22.9256"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </button>
          </div>
          <div class="buttons__wrapper-text">
            <i18n path="message.wizard.info">
              <template #breakMobile>
                <br class="br-mobile" />
              </template>
              <template #breakDesktop>
                <br class="br-desktop" />
              </template>
            </i18n>
          </div>
        </div>
        <button @click="result" class="result__btn" :disabled="!isResultPageAllowed">
          {{ $t('message.wizard.calc') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isResultPageAllowed, isValidNumber } from '@/utils'
import SlopeView from './SlopeView.vue'
import Nav from './Nav.vue'
import Materials from './Materials.vue'
import DeleteConfirmation from '@/components/DeleteConfirmation.vue'
import BottomPageNavigation from '@/components/smart/BottomPageNavigation'
import { stepNumbers } from '@/utils/stepNumbers'
import UpdateStepMixin from '@/components/mixin/UpdateStepMixin'
import { isEmpty } from 'lodash'

export default {
  mixins: [UpdateStepMixin],
  data() {
    return {
      pickedSlopeType: this.typeFromState,
      isShowDeleteConfirmation: false
    }
  },
  components: {
    BottomPageNavigation,
    SlopeView,
    Nav,
    Materials,
    DeleteConfirmation
  },
  methods: {
    ...mapActions({
      fetchMaterialsMain: 'fetchMaterialsMain',
      fetchMaterials: 'fetchMaterials',
      checkTNTokenAction: 'checkTNToken',
      updateTNToken: 'updateTNToken',
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults'
    }),
    showDeleteConfirmationModal() {
      this.isShowDeleteConfirmation = true
    },
    updateSlopeType() {
      this.$store.commit('updateSlopeType', {
        id: this.slopeId,
        type: this.slopeFromState.slopeType
      })
    },
    save() {
      this.isFilter = true
      this.resetSaveCalculationResult()
      this.$router.push({ name: 'Wizard', params: { id: parseInt(this.allSlopes.length, 10) } })
      this.$store.commit('addNewSlope', { id: parseInt(this.allSlopes.length, 10) + 1 })
      setTimeout(() => this.$refs.Nav.$refs.mySwiper.swiper.slideTo(this.allSlopes.length, 1000), 0)
    },
    result() {
      if (this.isResultPageAllowed) {
        this.$router.push({ name: 'Result' })
      }
    },
    deleteSlope() {
      if (this.allSlopes.length - 1 === parseInt(this.slopeId, 10)) {
        this.$store.commit('deleteSlope', this.slopeId)
        this.$router.push({ name: 'Wizard', params: { id: this.slopeId - 1 } })
        this.isShowDeleteConfirmation = false
        this.$root.onBodyScroll()
      } else {
        this.$store.commit('deleteSlope', this.slopeId)
        this.isShowDeleteConfirmation = false
        this.$root.onBodyScroll()
      }
    },
    updateMetric() {
      this.updateStep(this.currentStepNumber)
    },
    checkTNToken() {
      const token = this.$route.query.tnt
      if (token) {
        this.checkTNTokenAction(token)
      } else {
        this.updateTNToken()
      }
    },
    getContentByCategory(category) {
      const isValidCategory = isValidNumber(category) && [1, 2, 3].includes(category)
      if (isValidCategory) {
        this.fetchMaterialsMain(category)
        sessionStorage.setItem('category', category)
      } else {
        this.fetchMaterials()
      }
    },
    resetSaveCalculationResult() {
      this.resetUserInfoResults()
      this.markCalculationAsSaved(null)
    }
  },
  mounted() {
    const { $route } = this
    const routeQuery = $route.query
    const isRouteQueryExist = !isEmpty(routeQuery)
    if (isRouteQueryExist) {
      if (routeQuery.category && !isEmpty(routeQuery.category)) {
        const category = Number(routeQuery.category)
        this.getContentByCategory(category)
      } else {
        this.fetchMaterials()
      }
    } else {
      if (sessionStorage.getItem('category')) {
        const category = Number(sessionStorage.getItem('category'))
        this.getContentByCategory(category)
      } else {
        this.fetchMaterials()
      }
    }
    this.checkTNToken()

    if (this.slopeId >= this.allSlopes.length) {
      this.$router.push('/')
    }
  },
  updated() {
    window.parent.postMessage({ scrollTop: true }, '*')
  },
  watch: {
    currentStepNumber: {
      handler: 'updateMetric',
      immediate: true
    },
    isShowDeleteConfirmation() {
      this.isShowDeleteConfirmation === true
        ? this.$root.offBodyScroll()
        : this.$root.onBodyScroll()
    }
  },
  computed: {
    ...mapState({
      materials: state => state.materials,
      categoryFromState: state => state.category,
      allSlopes: state => state.slopes
    }),
    currentStepNumber() {
      const { slopeFromState } = this
      const isSlopeTypeSelected = !!slopeFromState.slopeType
      const isMaterialSelected = !!slopeFromState.material
      const [currentStep] = [
        !isSlopeTypeSelected && !isMaterialSelected && stepNumbers.wedgeTypes,
        isSlopeTypeSelected && !isMaterialSelected && stepNumbers.materials,
        isSlopeTypeSelected && isMaterialSelected && stepNumbers.sector
      ].filter(Boolean)
      return currentStep
    },
    typeFromState() {
      return this.slopeFromState.SlopeType
    },
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? +this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    isResultPageAllowed() {
      return isResultPageAllowed(this.allSlopes)
    },
    slopeTypes() {
      return [
        {
          id: 0,
          title: this.$t('message.wizard.slopeTypes')[0].name,
          text: this.$t('message.wizard.slopeTypes')[0].text,
          src: require('@/assets/img/mainSlope.svg'),
          value: 'mainSlope'
        },
        {
          id: 1,
          title: this.$t('message.wizard.slopeTypes')[1].name,
          text: this.$t('message.wizard.slopeTypes')[1].text,
          src: require('@/assets/img/contrSlope.svg'),
          value: 'contrSlope'
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.selectionPage
  .slopePage
    &__title
      +media((padding-top: (320: rem(32), 768: rem(34))))
      +media((padding-bottom: (320: rem(22), 768: rem(34))))
      margin-left: rem(-15)
.slope
  &__selection
    margin-left: rem(-15)
    margin-right: rem(-15)
    display: grid
    grid-template-rows: 1fr
    grid-template-columns: 1fr 1fr
    +media((grid-column-gap: (320: 0, 768: rem(10))))
    +media((padding: (320: 0 rem(24), 768: 0)))
    margin-bottom: rem(51)
    &-item
      background: $white
      border-radius: rem(4)
      transition: .5s
      cursor: pointer
      +media((padding: (320: rem(35) rem(19) rem(28), 768: rem(40))))
      &:hover
        box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16)
        .slope__selection-item__img
          &:before
            opacity: 1
      input
        opacity: 0
        position: absolute
        pointer-events: none
      &__img
        position: relative
        img
          max-width: 100%
        &:before
          content: ""
          height: rem(50)
          width: rem(50)
          position: absolute
          top: 50%
          left: 50%
          transition: .5s
          pointer-events: none
          opacity: 0
          transform: translate(-50%, -50%)
          background-image: url(~@/assets/img/select-tick.svg)
      &__title
        font-style: normal
        font-weight: bold
        color: $main
        display: block
        +media((margin: (320: rem(30) 0 0, 768: rem(35) 0 rem(20))))
        +media((text-align: (320: center, 768: left)))
        +media((font-size: (320: rem(14), 768: rem(18))))
        +media((line-height: (320: rem(20), 768: rem(22))))

      &__text
        font-style: normal
        font-weight: 500
        font-size: rem(14)
        line-height: rem(24)
        text-align: left
        color: $black
        +media((display: (320: none, 768: block)))
.buttons
  &__wrapper
    display: flex
    justify-content: space-between
    margin-left: rem(-15)
    margin-right: rem(-15)
    +media((flex-direction: (320: column, 768: row)))
    +media((align-items: (320: flex-start, 768: flex-start)))
    +media((padding: (320: 0 rem(25), 768: 0 rem(24), 960: 0)))
    &-left
      display: flex
      flex-direction: column
      .buttons
        display: flex
        align-items: center
    &-text
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(20)
      color: $light_black
      text-align: left
      +media((margin: (320: rem(12) 0 rem(28), 768: rem(18) 0 rem(69))))
      +media((width: (320: calc(100% - 80px), 768: auto)))
      .br-mobile
        +media((display: (320: inline-block, 768: none)))
      .br-desktop
        +media((display: (320: none, 768: inline-block)))
    .delete
      &__btn
        flex: 0 0 rem(50)
        margin-left: rem(6)
    .save, .result
      &__btn
        font-family: $main-font
        font-style: normal
        font-weight: bold
        color: $white
        border: none
        outline: none
        box-shadow: none
        height: rem(50)
        display: flex
        flex-direction: row
        align-items: center
        cursor: pointer
        transition: .5s
        border-radius: rem(4)
    .save
      &__btn
        background: $green
        font-size: rem(14)
        line-height: rem(17)
        +media((line-height: (320: rem(20), 768: rem(17))))
        +media((padding: (320: 0 rem(20), 768: 0 rem(18))))
        margin: 0
        // +media((width: (320: calc(100% - 104px), 768: auto)))
        img
          padding-right: rem(20)
          +media((display: (320: none, 768: block)))
        &:hover
          background: $green-hover
    .result
      &__btn
        background: $main
        font-size: rem(18)
        line-height: rem(22)
        border-radius: rem(4)
        +media((padding: (320: 0 rem(24), 768: 0 rem(31))))
        justify-content: center
        +media((margin-bottom: (320: rem(32), 768: 0)))
        width: auto
        &:hover
          background: $main
          opacity: $hover-opacity
        &:disabled
          background: #959595
          cursor: no-drop
</style>
