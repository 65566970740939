<template>
  <div
    class="container"
    v-show="
      slopeFromState.slopeType !== '' &&
      slopeFromState.materialSize !== '' &&
      slopeFromState.materialSize.width === 0
    "
  >
    <div class="slopePage__title">
      <span v-if="type === 'mainSlope'">{{ $t('message.wizard.materials.mailSlope') }}</span>
      <span v-else>{{ $t('message.wizard.materials.contrSlope') }}</span>
    </div>
    <div class="slopePage__filter">
      <label
        for="all"
        class="slopePage__filter-item"
        :class="{ active: filter === null }"
        v-if="allMaterialsTypes.length > 1"
      >
        <input type="radio" v-model="filter" id="all" :value="null" />
        <span>{{ $t('message.wizard.materials.all') }}</span>
      </label>
      <label
        :for="item.name"
        v-for="item in allMaterialsTypes"
        :key="item.id"
        class="slopePage__filter-item"
        :class="{ active: filter === item.id || allMaterialsTypes.length === 1 }"
      >
        <input type="radio" v-model="filter" :id="item.name" :value="item.id" />
        <span>{{ item.name }}</span>
      </label>
    </div>
    <div class="slopePage__materials">
      <label
        v-for="material in currentMaterials"
        :key="material.id"
        class="slopePage__materials-item"
        :for="material.id"
        :class="{
          showInfo: showMaterialInfo === material.id
        }"
      >
        <input
          type="radio"
          :id="material.id"
          :value="material.id"
          v-model="computedSelectedMaterial"
        />
        <img :src="material.src" alt="" class="slopePage__materials-item--img" />
        <span class="slopePage__materials-item--name">
          {{ material.name }}
        </span>
        <span class="slopePage__materials-item--footer">
          <span class="slopePage__materials-item--angle">
            <img src="@/assets/img/angle.svg" alt="" />{{ material.angle }}%
          </span>
          <label :for="`material-${material.id}`">
            <input
              type="radio"
              :value="material.id"
              :id="`material-${material.id}`"
              v-model="showMaterialInfo"
            />
            <img src="@/assets/img/info.svg" alt="info" />
          </label>
        </span>
        <span class="slopePage__materials-item--info" v-show="showMaterialInfo === material.id">
          <button class="close" @click="showMaterialInfo = ''">
            <span v-html="getIcon('close')"></span>
          </button>
          <span class="title">
            {{ material.name }}
          </span>
          <span class="angle">
            <span class="slopePage__materials-item--angle">
              <img src="@/assets/img/angle.svg" alt="" />{{ material.angle }}%
            </span>
          </span>
          <span class="params">
            <span class="params__item" v-for="param in material.params" :key="param.id">
              {{ param.width }}x{{ param.height }} мм
            </span>
            <span class="params__divider">|</span>
          </span>
          <span class="text">{{ material.info_text }}</span>
          <span class="info" v-for="(plate, i) in material.plates" :key="i">
            <span class="info__name">
              {{ plate.name }}
            </span>
            <span class="info__val">
              {{ plate.value }}
            </span>
          </span>
          <a :href="material.link" target="_blank" class="link">
            <span v-html="getIcon('planet')"></span>подробнее
          </a>
        </span>
      </label>
      <button @click="showMore" v-if="isShowShowMoreBtn" class="showMore">
        <span>
          <b>+{{ remainingMaterialsCount }}</b> {{ word }}
        </span>
        <span class="slopePage__materials-item--angle">
          <img src="@/assets/img/angle.svg" alt="" />
          {{ showMoreBtnAngle }}%
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import {
  getFormattedWord,
  slopeTypes,
  filterMaterialsBySlopeType,
  filterMaterialsForMainSlope
} from '@/utils'
import { mapMutations } from 'vuex'
import IconMixin from '@/components/mixin/IconMixin'
const typesShowMoreAngle = {
  mainSlope: '4.2',
  contrSlope: '1.7'
}
const permittedShowMoreBtnTypes = ['mainSlope']
export default {
  mixins: [IconMixin],
  props: {
    type: String,
    isFilter: Boolean
  },
  data() {
    return {
      pickedMaterialParam: '',
      showMaterialInfo: '',
      showedAngle: 1.7,
      filter: null,
      isShowRemainingMaterials: false,
      materials: []
    }
  },
  methods: {
    ...mapMutations({
      UPDATE_SLOPE_MATERIAL: 'UPDATE_SLOPE_MATERIAL'
    }),
    showMore() {
      this.isShowRemainingMaterials = true
    }
  },
  computed: {
    computedSelectedMaterial: {
      get() {
        return this.slopeFromState.material
      },
      set(val) {
        this.isShowRemainingMaterials = false
        this.UPDATE_SLOPE_MATERIAL({
          sectorId: this.slopeId,
          materialId: val
        })
      }
    },
    materialsBySlopeType() {
      return filterMaterialsBySlopeType(this.allMaterials, this.type)
    },
    materialsForMainSlope() {
      // prettier-ignore
      return this.isShowRemainingMaterials
        ? filterMaterialsForMainSlope(this.allMaterials)
        : this.materialsBySlopeType
    },
    preparedMaterials() {
      const res =
        this.type === slopeTypes.mainSlope ? this.materialsForMainSlope : this.materialsBySlopeType
      return res.sort((a, b) => a.weight?.localeCompare(b?.weight))
    },
    currentMaterials() {
      return this.filter
        ? this.preparedMaterials.filter(material => material.type === this.filter)
        : this.preparedMaterials
    },
    remainingMaterials() {
      return this.allMaterials.filter(
        material => material.isShowOnMainSlope && material.slope === slopeTypes.contrSlope
      )
    },
    remainingMaterialsCount() {
      const filteredRemainingMaterials = this.filter
        ? this.remainingMaterials.filter(material => material.type === this.filter)
        : this.remainingMaterials
      return filteredRemainingMaterials.length
    },
    word() {
      return getFormattedWord(this.$t('message.wizard.materials.words'), this.remainingMaterialsCount)
    },
    isShowShowMoreBtn() {
      return (
        !this.isShowRemainingMaterials &&
        permittedShowMoreBtnTypes.includes(this.type) &&
        this.allMaterials.some(
          material => material.isShowOnMainSlope && material.slope === slopeTypes.contrSlope
        )
      )
    },
    showMoreBtnAngle() {
      return typesShowMoreAngle[this.type]
    },
    typeFromState() {
      return this.slopeFromState.slopeType
    },
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    allMaterials() {
      return this.$store.getters.allMaterials
    },
    allMaterialsTypes() {
      return this.$store.getters.allMaterialsTypes
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.slopePage
  &__title
    margin-left: rem(-15)
    padding-bottom: rem(16)
  &__materials
    display: grid
    grid-template-rows: 1fr
    grid-column-gap: rem(1)
    grid-row-gap: rem(1)
    margin-left: rem(-15)
    margin-right: rem(-15)
    +media((grid-template-columns: (320: repeat(2, 1fr), 650: repeat(3, 1fr), 992: repeat(4, 1fr))))
    &-item
      background: $white
      +media((padding: (320: rem(12) rem(12) rem(15), 768: rem(17) rem(26) rem(20))))
      text-align: left
      transition: .5s
      z-index: 1
      cursor: pointer
      position: relative
      img
        max-width: 100%
        margin: auto
        display: block
        max-height: rem(101)
      input[type="radio"]
        position: absolute
        opacity: 0
        pointer-events: none
      &:hover
        box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16)
        z-index: 2
        .slopePage__materials-item--img
          opacity: .7
      &.showInfo
        z-index: 2
      &--active
        .slopePage__materials-item--variants
          opacity: 1
          pointer-events: auto
      &--img
        transition: .5s
      &--name
        font-style: normal
        font-weight: 600
        font-size: rem(14)
        color: $main
        display: block
        +media((min-height: (320: rem(64), 370: rem(42), 768: rem(52))))
        +media((padding-top: (320: rem(20), 768: rem(18))))
        +media((line-height: (320: rem(22), 768: rem(17))))
      &--angle
        font-style: normal
        font-weight: bold
        font-size: rem(14)
        line-height: rem(17)
        //padding-top: rem(6)
        color: $black
        display: flex
        flex-direction: row
        align-items: center
        img
          padding-right: rem(6)
          margin: 0
      &--footer
        padding-top: rem(6)
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        label
          line-height: 0
          cursor: pointer
      &--info
        background: $white
        box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16)
        border-radius: rem(4)
        +media((position: (320: fixed, 480: absolute)))
        top: 50%
        transform: translateY(-50%)
        z-index: 3
        left: 0
        right: 0
        margin: auto
        padding: rem(16) rem(20) rem(18)
        color: $black
        transition: .5s
        +media((width: (320: 80vw, 380: 70vw, 410: 60vw, 480: 100%)))
        +media((box-shadow: (320: 0 0 rem(10) rem(500) rgba(0, 0, 0, 0.62), 480: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16))))
        .title
          display: block
          font-style: normal
          font-weight: 500
          font-size: rem(14)
          line-height: rem(17)
          color: $black
          padding-bottom: rem(5)
          padding-right: rem(10)
        .params
          font-style: normal
          font-weight: bold
          font-size: rem(12)
          line-height: rem(20)
          display: flex
          flex-direction: row
          align-items: center
          padding-top: rem(18)
          padding-bottom: rem(6)
          &__item
            position: relative
            padding-right: rem(9)
            order: 3
            &:first-of-type
              order: 1
              //&:after
                content: "|"
                color: $red
                position: absolute
                right: rem(8)
          &__divider
            color: $main
            order: 2
            padding-right: rem(9)
        .text
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: rem(18)
          color: $tn_gray_plus
          padding-bottom: rem(17)
          display: block
        .info
          display: flex
          flex-direction: row
          align-items: center
          padding-bottom: rem(5)
          &__name
            font-style: normal
            font-weight: 500
            font-size: rem(12)
            line-height: rem(20)
            color: $light_black
            width: rem(120)
          &__val
            font-style: normal
            font-weight: 600
            font-size: rem(12)
            line-height: rem(20)
            color: $light_black
        .link
          color: $main
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: rem(20)
          display: flex
          align-items: center
          flex-direction: row
          text-decoration: none
          padding-top: rem(14)
          span
            display: flex
            align-items: center
            padding-right: rem(12)
            margin: 0
          &:hover
            text-decoration: underline
        .close
          border: none
          box-shadow: none
          outline: none
          cursor: pointer
          background: none
          padding: 0
          margin: 0
          line-height: 0
          padding: rem(13)
          position: absolute
          top: 0
          right: 0
  &__filter
    border-bottom: rem(1) solid $another-white
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    margin-left: rem(-15)
    margin-right: rem(-15)
    margin-bottom: rem(34)
    overflow-x: auto
    overflow-y: hidden
    +media((margin-left: (320: rem(9), 768: 0)))
    &-item
      font-style: normal
      font-weight: bold
      font-size: rem(14)
      line-height: rem(17)
      display: flex
      color: $light_black
      cursor: pointer
      transition: .5s
      position: relative
      +media((padding-right: (320: rem(20), 768: rem(30))))
      box-sizing: content-box
      span
        position: relative
        display: block
        height: 100%
        padding: rem(25) 0
        white-space: nowrap
      input[type="radio"]
        position: absolute
        opacity: 0
        pointer-events: none
      &.active
        color: $black
        span
          &:after
            content: ""
            position: absolute
            left: 0
            bottom: rem(-1)
            width: 100%
            height: rem(4)
            background: $main
.showMore
  background: none
  box-shadow: none
  border: none
  outline: none
  background: rgba(255, 255, 255, 0.5)
  border: rem(1) solid $another-white
  box-sizing: border-box
  border-radius: rem(4)
  border-style: dashed
  font-family: $main-font
  font-style: normal
  font-weight: 500
  font-size: rem(14)
  line-height: rem(17)
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  color: $main
  cursor: pointer
  padding: 0
  margin: rem(20)
  min-height: rem(164)
  span
    padding-bottom: rem(2)
    padding-top: rem(2)
</style>
