<template>
  <div class="fastener-selection">
    <div class="title">{{ $t('message.sectorFastener.fastenerSelection.title') }}</div>
    <multiselect
      v-model="computedFastener"
      :placeholder="$t('message.sectorFastener.fastenerSelection.placeholder')"
      label="name"
      track-by="id"
      :options="filteredFasteners"
      :option-height="40"
      :show-labels="false"
      :allow-empty="false"
      :searchable="false"
      open-direction="bottom"
    >
      <template #singleLabel="fasteners">
        <div class="option__box">
          <div class="option__img">
            <img :src="fasteners.option.image" :alt="fasteners.option.name" />
          </div>
          <div class="option__info">
            <div class="option__info-title">{{ fasteners.option.name }}</div>
          </div>
        </div>
      </template>
      <template #option="fasteners">
        <div class="option__box">
          <div class="option__img">
            <img :src="fasteners.option.image" :alt="fasteners.option.name" />
          </div>
          <div class="option__info">
            <div class="option__info-title">{{ fasteners.option.name }}</div>
          </div>
        </div>
      </template>
      <template #caret>
        <span class="multiselect__select" v-html="getIcon('caret')"></span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import IconMixin from '@/components/mixin/IconMixin'
export default {
  mixins: [IconMixin],
  props: {
    currentBasement: {
      type: Number,
      required: true
    },
    currentFastener: {
      type: Number,
      required: true
    }
  },
  name: 'FastenerSelection',
  components: { Multiselect },
  computed: {
    ...mapState({
      allFasteners: state => state.fasteners
    }),
    filteredFasteners() {
      return this.allFasteners.filter(fastener =>
        fastener.baseTypeId.includes(this.currentBasement)
      )
    },
    computedFastener: {
      get() {
        return this.allFasteners.find(f => f.id === this.currentFastener)
      },
      set(val) {
        this.$emit('updateFastener', val.id)
      }
    }
  }
}
</script>
<style lang="sass">
.fastener-selection
  .multiselect__content-wrapper
  // max-height: rem(204)!important
  .multiselect__option--selected
    background: #eeeeee
  // .option-info__title
    font-weight: 700!important
  .multiselect__option--highlight
    background: $main
    .option__info-angle
      svg
        path
          fill: $white
  .multiselect__option--selected.multiselect__option--highlight
    background: $main
    opacity: $hover-opacity
  .multiselect
    width: rem(600)
    max-width: 100%
    min-height: rem(40)
    color: $black
    cursor: pointer
    .multiselect
      &__tags, &__single
        padding: 0
        margin: 0
        background: transparent
      &__tags
        padding: 0
        box-sizing: border-box
        border-radius: 0
        border: rem(1) solid $ec
      &__select
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        cursor: pointer
        &:before
          content: none
    .option
      &__box
        display: flex
        flex-direction: row
        align-items: center
      &__img
        display: flex
        flex-direction: row
        align-items: center
        +media((padding-right: (320: rem(10), 768: rem(20))))
      &__info
        font-size: rem(14)
        line-height: 100%
        padding-right: rem(40)
        &-title
          font-style: normal
          font-weight: 500
          padding: rem(10) 0
</style>
<style scoped lang="sass">
.fastener-selection
  display: flex
  margin-top: rem(28)
  flex-direction: column
  .title
    text-align: left
    @extend %title-mini
    margin: 0 0 rem(16) 0
</style>
