<template>
  <sector-counts-controls
    :isInline="isInline"
    :class="{ inline: isInline }"
    :count="slope.slopeLayersCount"
    :min="min"
    @updateCount="updateLayersCount"
  >
    {{
      isInline ? $t('message.controls.counter.inline') : $t('message.controls.counter.notInline')
    }}
    {{ $t('message.controls.counter.layers') }}

    <template #tooltip>
      <tippy
        interactive
        placement="right"
        maxWidth="400"
        theme="light"
        animation="shift-toward"
        arrow="false"
      >
        <template #trigger>
          <div class="title__tooltip">
            <span v-html="getIcon('tooltip')"></span>
          </div>
        </template>
        <span class="tooltip__body">
          <span class="tooltip__body-text"> {{ $t('message.controls.counter.define') }} </span>
          <a class="tooltip__body-link" :href="$t('message.links.layerCounter')" target="_blank">
            <span class="icon"><span v-html="getIcon('tooltipLink')"></span></span>
            {{ $t('message.general.site') }}
          </a>
        </span>
      </tippy>
    </template>
  </sector-counts-controls>
</template>

<script>
import { mapMutations } from 'vuex'
import SectorCountsControls from '@/components/dump/SectorCountsControls'
import IconMixin from '@/components/mixin/IconMixin'

export default {
  mixins: [IconMixin],
  props: {
    slope: Object,
    isInline: Boolean,
    min: Number
  },
  name: 'layerCounter',
  components: { SectorCountsControls },
  methods: {
    ...mapMutations({
      updateSlopeLayersCount: 'updateSlopeLayersCount'
    }),
    updateLayersCount(count) {
      this.updateSlopeLayersCount({
        id: this.slopeId,
        count
      })
    }
  },
  computed: {
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/assets/sass/inline-sector.sass'
::v-deep.inline
  margin-top: rem(10)
  padding: 0
  justify-content: flex-start
  .text
    @include text
    width: rem(93)
    line-height: 125%
  .input
    height: rem(40)
    width: rem(97)
    border-color: #D6D6D6
    color: #999
    margin-left: 0
  .btn
    background: #ed8189
    &:last-child
      margin-right: rem(10)
.title__tooltip
  @include title-tooltip
</style>
