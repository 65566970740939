<template>
  <div class="roof-options__material">
    <div class="title">{{ $t('message.sectorRoof.roofMaterial.title') }}</div>
    <multiselect
      v-model="computedMaterial"
      placeholder="Поиск..."
      label="name"
      track-by="id"
      :options="allMaterials"
      :option-height="68"
      :show-labels="false"
      :allow-empty="false"
      :searchable="false"
      open-direction="bottom"
    >
      <template #singleLabel="materials">
        <div class="option__box">
          <div class="option__img">
            <img :src="materials.option.src" :alt="materials.option.name" />
          </div>
          <div class="option__info">
            <div class="option__info-title">{{ materials.option.name }}</div>
            <div class="option__info-angle">
              <img src="@/assets/img/angle.svg" alt="" />{{ materials.option.angle }}%
            </div>
          </div>
        </div>
      </template>
      <template #option="materials">
        <div class="option__box">
          <div class="option__img">
            <img :src="materials.option.src" :alt="materials.option.name" />
          </div>
          <div class="option__info">
            <div class="option__info-title">{{ materials.option.name }}</div>
            <div class="option__info-angle">
              <img src="@/assets/img/angle.svg" alt="" />
              {{ materials.option.angle }}%
            </div>
          </div>
        </div>
      </template>
      <template #caret>
        <span class="multiselect__select" v-html="getIcon('caret')"></span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import IconMixin from '@/components/mixin/IconMixin'
// import { sortMaterialsByAngle } from '@/utils'

export default {
  mixins: [IconMixin],
  props: {
    slope: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  name: 'RoofOptionsMaterial',
  components: { Multiselect },
  methods: {},
  computed: {
    ...mapState({
      allMaterials: state => state.materials
    }),
    // sortedMaterials() {
    //   return sortMaterialsByAngle(this.allMaterials)
    // },
    currentMaterial() {
      return this.allMaterials.find(material => {
        return material.id === this.slope.roofOptions.material
      })
    },
    computedMaterial: {
      get() {
        return this.currentMaterial
      },
      set(val) {
        this.$emit('updateMaterial', val)
      }
    }
  }
}
</script>

<style lang="sass">
.roof-options__material
  .title
    font-style: normal
    font-weight: bold
    font-size: rem(12)
    line-height: 130%
    text-align: left
    margin-bottom: rem(10)
  .multiselect__content-wrapper
    // max-height: rem(204)!important
  .multiselect__option--selected
    background: #eeeeee
    // .option-info__title
      font-weight: 700!important
  .multiselect__option--highlight
    background: $main
    .option__info-angle
      img
        filter: invert(1)
  .multiselect__option--selected.multiselect__option--highlight
    background: $main
    opacity: $hover-opacity
  .multiselect
    width: rem(531)
    max-width: 100%
    min-height: rem(68)
    color: $black
    cursor: pointer
    .multiselect
      &__tags, &__single
        padding: 0
        margin: 0
        background: transparent
      &__tags
        padding: rem(10) rem(12)
        border: rem(1) solid $ec
      &__select
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        cursor: pointer
        &:before
          content: none
    .option
      &__box
        display: flex
        flex-direction: row
        align-items: center
      &__img
        display: flex
        flex-direction: row
        align-items: center
        padding-right: rem(16)
        img
          height: rem(47)
      &__info
        font-size: rem(14)
        line-height: rem(17)
        padding-right: rem(40)
        &-title
          font-style: normal
          font-weight: 500
          padding-bottom: rem(6)
        &-angle
          font-family: $main-font
          font-style: normal
          font-weight: bold
          img
            padding-right: rem(6)
</style>
