<template>
  <div class="sector-name">
    <label for="sector-name" class="sector-name__control">
      <span class="icon" v-html="getIcon('pencil')"></span>
      <input type="text" class="input" id="sector-name" v-model="computedSectorName" />
    </label>
  </div>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'

export default {
  mixins: [IconMixin],
  name: 'SectorNameChanger',
  props: {
    name: {
      type: String,
      default: null,
      required: true
    }
  },
  computed: {
    computedSectorName: {
      get() {
        return this.name
      },
      set(val) {
        this.$emit('updateName', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.sector-name
  background: $white
  margin: 0 rem(-15)
  +media((padding: (320: rem(34) rem(25), 992: rem(34) rem(40))))
  &__control
    border: rem(1) solid $another-white
    box-sizing: border-box
    border-radius: rem(4)
    padding: rem(10)
    display: grid
    grid-template-columns: rem(32) 1fr
    gap: rem(12)
    align-items: center
    .icon
      line-height: 0
      height: rem(32)
      width: rem(32)
      display: flex
      align-items: center
      justify-content: center
    .input
      width: 100%
      font-family: $main-font
      font-style: normal
      font-weight: bold
      font-size: rem(14)
      line-height: 17px
      height: 100%
      border: none
      padding: 0
      outline: none
      text-overflow: ellipsis
</style>
