<template>
  <section class="slopePage">
    <div class="container">
      <div
        class="row align-items-center justify-content-between flex-nowrap slopePage__title-wrapper"
      >
        <div class="slopePage__title slopePage__title-withBtn">
          {{ $t('message.wizard.contrSlope.title') }}
        </div>
        <button
          @click="showDeleteConfirmationModal"
          class="delete__btn"
          v-if="this.allSlopes.length !== 1"
        >
          <svg
            width="19"
            height="25"
            viewBox="0 0 19 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 2.91667L18 5.29333M5.73105 3.60667L7.89615 1L12.1461 1.61333L13.349
            4.60333M12.467 12.1933L12.2264 19.5533M6.61321 12.1933L6.85377 19.63M1.56128
            7.74667H17.4386L16.6368 21.7C16.5566 22.9267 15.5141 24 14.2311 24H4.76883C3.40562 24
            2.44336 22.9267 2.36317 21.7L1.56128 7.74667Z"
              stroke="#999999"
              stroke-width="2"
              stroke-miterlimit="22.9256"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </div>

      <sector-name-changer :name="slopeFromState.name" @updateName="updateSectorName" />
      <div class="row slopeForm slopePage__pane">
        <div class="col-12">
          <div class="slopePage__subtitle">{{ $t('message.wizard.contrSlope.shape') }}</div>
        </div>
        <div class="col-12 slopeForm__box">
          <div v-for="item in slopeForms" :key="item.id" class="slopeForm__box-item">
            <label
              class="slopeForm__box-item__btn"
              :for="item.value"
              :class="{
                'slopeForm__box-item__btn--active': slopeFromState.slopeForm === item.value
              }"
            >
              <input
                type="radio"
                :id="item.value"
                :value="item.value"
                v-model="slopeFromState.slopeForm"
                class="slopeForm__box-item__btn__input"
                @change="updateSlopeForm"
              />
              <img :src="item.src" :alt="item.value" width="100%" class="img img--forward" />
              <img :src="item.srcActive" :alt="item.value" width="100%" class="img img--backward" />
            </label>
          </div>
        </div>
      </div>
      <roof-options
        :slope="slope"
        @updateRoofSlope="updateRoofSlope"
        @updateRoofBasement="updateRoofBasement"
        @updateRoofMaterial="updateRoofMaterial"
      />
      <div class="figure__box slopePage__pane">
        <div class="col-12">
          <div class="slopePage__subtitle slopePage__subtitle-figure">
            {{ $t('message.wizard.contrSlope.size') }}
          </div>
        </div>
        <div class="slopePage__inputs-figure">
          <param-input
            :form="slopeFromState.slopeForm"
            :param="['width', slopeParams.width]"
            @updateParam="updateSlopeParam"
          />
          <param-input
            v-if="slopeParams.bias1 >= 0"
            can-be-zero
            :form="slopeFromState.slopeForm"
            :param="['bias1', slopeParams.bias1]"
            @updateParam="updateSlopeParam"
          />
          <param-input
            v-if="isShowOffsetValleyInput"
            can-be-zero
            :form="slopeFromState.slopeForm"
            :param="['offsetValley', slopeParams.offsetValley]"
            @updateParam="updateSlopeParam"
          />
          <ratio-switcher
            :ratio="slopeParams.ratio"
            :form="slopeFromState.slopeForm"
            @updateRatio="updateSlopeRatio"
          />
          <layer-counter :slope="slope" :min="minLayerCount" is-inline></layer-counter>
        </div>
        <div class="slopePage__figure-figure">
          <component
            :is="currentFigureComponent"
            :slopeParams="slopeFromState.slopeParams"
            :defaultSvgParams="defaultSvgParams"
            :isOffsetValleyNeed="isShowOffsetValleyInput"
          >
          </component>
        </div>
      </div>
      <SimilarInputs :slope="slope" />
    </div>
    <confirmation-modal :is-modal-open="isModalOpen" @close="close">
      <template #text>
        {{ $t('message.wizard.contrSlope.mainSlope') }}
        <b>{{ roofSlope() }}{{ $t('message.units.percent') }} .</b><br />
        {{ $t('message.wizard.contrSlope.margin') }}
        <b>{{ slopeParams.offsetValley }} {{ $t('message.units.m') }}.</b><br />
        {{ $t('message.wizard.contrSlope.requirements') }} <br />
        <ul>
          <li>
            {{ $t('message.wizard.contrSlope.aspect') }}
            <b>{{ $t('message.wizard.contrSlope.aspectSides') }}</b>
            {{ $t('message.wizard.contrSlope.aspectEqual') }}
            <b>{{ $t('message.wizard.contrSlope.equal') }} {{ recommendedRatio }}</b>
          </li>
          <li>
            {{ $t('message.wizard.contrSlope.isolation') }} <b>{{ recommendedMinLayerCount }}</b>
          </li>
        </ul>
      </template>
      <template #title>
        <div class="title">{{ $t('message.wizard.contrSlope.accept') }}</div>
      </template>
      <template #cancel>
        <button class="btn btn--grey" @click="close">
          {{ $t('message.wizard.contrSlope.buttons.no') }}
        </button>
      </template>
      <template #confirm>
        <button class="btn btn--red" @click="confirm">
          {{ $t('message.wizard.contrSlope.buttons.yes') }}
        </button>
      </template>
    </confirmation-modal>
  </section>
</template>
<script>
import { defaultSvgParams } from '@/utils/drawing'
import { mapMutations, mapState } from 'vuex'
import SimilarInputs from '@/components/SimilarInputs.vue'
import ParamInput from '@/components/figure_controls/paramInput'
import RatioSwitcher from '@/components/figure_controls/ratioSwitcher'
import LayerCounter from '@/components/figure_controls/layerCounter'
import SectorNameChanger from '@/components/dump/SectorNameChanger'
import RoofOptions from '@/components/dump/sector_roof_options'
import { ratios } from '@/utils'
import ConfirmationModal from '@/components/dump/ConfirmationModal'
const forms = [
  {
    id: 0,
    value: 'triangle',
    src: require('@/assets/img/forms/contr/triangle.png'),
    srcActive: require('@/assets/img/forms/contr/triangle--active.png'),
    component: () => import('@/components/figures/Triangle')
  },
  {
    id: 1,
    value: 'doubleSideTriangleHorizontal',
    src: require('@/assets/img/forms/contr/doubleSideTriangleHorizontal.png'),
    srcActive: require('@/assets/img/forms/contr/doubleSideTriangleHorizontal--active.png'),
    component: () => import('@/components/figures/DoubleSideTriangleHorizontal')
  },
  {
    id: 2,
    value: 'doubleSideTriangleVertical',
    src: require('@/assets/img/forms/contr/doubleSideTriangleVertical.png'),
    srcActive: require('@/assets/img/forms/contr/doubleSideTriangleVertical--active.png'),
    component: () => import('@/components/figures/DoubleSideTriangleVertical')
  },
  {
    id: 3,
    value: 'rhombus',
    src: require('@/assets/img/forms/contr/rhombus.png'),
    srcActive: require('@/assets/img/forms/contr/rhombus--active.png'),
    component: () => import('@/components/figures/Rhombus')
  },
  {
    id: 4,
    value: 'rectangularTrapezoidVertical',
    src: require('@/assets/img/forms/contr/rectangularTrapezoidVertical.png'),
    srcActive: require('@/assets/img/forms/contr/rectangularTrapezoidVertical--active.png'),
    component: () => import('@/components/figures/RectangularTrapezoidVertical')
  },
  {
    id: 5,
    value: 'rectangularTrapezoidHorizontal',
    src: require('@/assets/img/forms/contr/rectangularTrapezoidHorizontal.png'),
    srcActive: require('@/assets/img/forms/contr/rectangularTrapezoidHorizontal--active.png'),
    component: () => import('@/components/figures/RectangularTrapezoidHorizontal')
  },
  {
    id: 6,
    value: 'doubleSideRectangularTrapezoidVertical',
    src: require('@/assets/img/forms/contr/doubleSideRectangularTrapezoidVertical.png'),
    srcActive: require('@/assets/img/forms/contr/doubleSideRectangularTrapezoidVertical--active.png'),
    component: () => import('@/components/figures/DoubleSideRectangularTrapezoidVertical')
  }
]
export default {
  props: {
    slope: Object
  },
  data() {
    return {
      picked: '',
      isShowDeleteConfirmation: false,
      isModalOpen: false,
      recommendedRatio: 3,
      recommendedMinLayerCount: 1,
      minLayerCount: 1
    }
  },
  components: {
    ConfirmationModal,
    RoofOptions,
    SectorNameChanger,
    RatioSwitcher,
    ParamInput,
    SimilarInputs,
    LayerCounter
  },
  methods: {
    ...mapMutations({
      UPDATE_SLOPE_PARAM: 'UPDATE_SLOPE_PARAM',
      UPDATE_ROOF_OPTIONS_PARAM: 'UPDATE_ROOF_OPTIONS_PARAM',
      updateSlopeName: 'updateSlopeName'
    }),
    close() {
      this.isModalOpen = false
    },
    confirm() {
      this.isModalOpen = false
      this.updateSectorSize()
    },
    updateSectorSize() {
      this.updateSlopeParam({ key: 'ratio', val: this.recommendedRatio })
      this.changeSlopeLayersCount()
    },
    updateSlopeParam(param) {
      const { key, val } = param
      this.UPDATE_SLOPE_PARAM({
        id: this.slopeId,
        key,
        val
      })
      if (key === 'offsetValley') {
        this.calcMinLayerCount(true)
      }
    },
    updateSlopeForm() {
      this.$emit('updateSlopeForm', { id: this.slopeId, form: this.slopeFromState.slopeForm })
      this.calcMinLayerCount(true)
    },
    updateSlopeWidth(e) {
      if (!isNaN(e.target.valueAsNumber)) {
        this.$store.commit('updateSlopeWidth', { id: this.slopeId, width: e.target.valueAsNumber })
      }
    },
    updateRoofSlope(val) {
      this.UPDATE_SLOPE_PARAM({
        id: this.slopeId,
        key: 'roofSlope',
        val
      })
      this.compareSlopeWithRatio(this.slopeParams.roofSlope)
    },
    updateRoofBasement(val) {
      this.UPDATE_ROOF_OPTIONS_PARAM({
        id: this.slopeId,
        key: 'base',
        val
      })
      this.compareSlopeWithRatio(this.roofSlope(), true)
    },
    updateRoofMaterial(val) {
      this.UPDATE_ROOF_OPTIONS_PARAM({
        id: this.slopeId,
        key: 'material',
        val: val.id
      })
      this.compareSlopeWithRatio(val.angle)
    },
    compareSlopeWithRatio(currentValue, isSilent = false) {
      this.calcMinLayerCount()
      this.recommendedRatio = ratios.find(p => p.min <= currentValue && currentValue < p.max).value
      if (
        this.slopeFromState.slopeParams[0].ratio !== this.recommendedRatio ||
        this.minLayerCount !== this.recommendedMinLayerCount
      ) {
        isSilent ? this.updateSectorSize() : (this.isModalOpen = true)
      }
    },
    calcMinLayerCount(withConfirm = false) {
      if (this.isLayerFixed) {
        this.recommendedMinLayerCount = 1
        this.layerCountShowModal(withConfirm)
        return
      }
      const materialAngle =
        this.allMaterials.find(el => el.id === this.slopeFromState.material)?.angleValue ?? 1
      const minAngle = 0.5
      this.recommendedMinLayerCount = Math.ceil((this.roofSlope(true) + minAngle) / materialAngle)
      this.layerCountShowModal(withConfirm)
    },
    layerCountShowModal(withConfirm = false) {
      if (withConfirm && this.minLayerCount !== this.recommendedMinLayerCount) {
        this.isModalOpen = true
      }
    },
    updateSlopeRatio(val) {
      // this.$store.commit('updateSlopeRatio', { id: this.slopeId, ratio: val })
      this.UPDATE_SLOPE_PARAM({
        id: this.slopeId,
        key: 'ratio',
        val
      })
    },
    changeSlopeLayersCount() {
      this.minLayerCount = this.recommendedMinLayerCount
      this.$store.commit('updateSlopeLayersCount', {
        id: this.slopeId,
        count: this.minLayerCount
      })
    },
    changeFixtureCalculation() {
      this.$store.commit('updateFixtureCalculation', {
        id: this.slopeId,
        need: this.slope.fixtureCalculation.need
      })
    },
    changeRoofBaseType() {
      this.$store.commit('updateFixtureCalculationType', {
        id: this.slopeId,
        type: this.slope.fixtureCalculation.type
      })
    },
    changeFixtureCalculationThickness() {
      this.$store.commit('updateFixtureCalculationThickness', {
        id: this.slopeId,
        val: this.slope.fixtureCalculation.thickness
      })
    },
    showDeleteConfirmationModal() {
      this.$emit('showDeleteConfirmationModal')
    },
    updateSectorName(name) {
      this.updateSlopeName({
        id: this.slopeId,
        name
      })
    },
    roofSlope(forCompute = false) {
      const material = this.originalRoofSlopeMaterial
      const baseAngle = forCompute ? material.angleValue : material.angle
      return this.slopeFromState.roofOptions.base === 'default'
        ? this.slopeParams.roofSlope
        : baseAngle
    }
  },
  watch: {},
  computed: {
    ...mapState({
      allMaterials: state => state.materials
    }),
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    isLayerFixed() {
      const figuresWithFixedLayersCount = ['doubleSideTriangleHorizontal', 'rhombus', 'doubleSideTriangleVertical', 'triangle']

      return (
        figuresWithFixedLayersCount.includes(this.slopeFromState.slopeForm) ||
        (['rectangularTrapezoidHorizontal'].includes(this.slopeFromState.slopeForm) && this.slopeParams.offsetValley === 0)
      )
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    defaultSvgParams: () => defaultSvgParams,
    slopeParams() {
      const [paramsKit] = this.slopeFromState.slopeParams
      return paramsKit
    },
    originalRoofSlopeMaterial() {
      return this.allMaterials.find(material => {
        return material.id === this.slopeFromState.roofOptions.material
      })
    },
    slopeForms: () => forms,
    isShowOffsetValleyInput() {
      const { slopeParams } = this
      return slopeParams.offsetValley !== null && slopeParams.offsetValley !== undefined
    },
    currentFigureComponent() {
      return forms.find(f => f.value === this.slopeFromState.slopeForm).component
    }
  },
  mounted() {}
}
</script>

<style lang="sass"></style>
