<template>
  <div class="roof-options__base">
    <div class="title">{{ $t('message.sectorRoof.roofBase.title') }}</div>
    <div class="basements">
      <basement-type-radio
        v-for="item in basements"
        id="roof"
        :key="item.id"
        :item="item"
        :current-basement="currentBasement"
        @updateBasement="updateBasement"
      />
    </div>
  </div>
</template>

<script>
import { roofOptionsBasements } from '@/utils'
import BasementTypeRadio from '@/components/dump/BasementTypeRadio'
export default {
  props: {
    roofOptions: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  name: 'RoofOptionsBase',
  components: { BasementTypeRadio },
  methods: {
    updateBasement(newBasement) {
      const base = this.basements.find(basement => basement.id === newBasement).base
      this.$emit('updateBasement', base)
    }
  },
  computed: {
    currentBasement() {
      return roofOptionsBasements.find(basement => basement.base === this.roofOptions.base).id
    },
    basements() {
      return roofOptionsBasements.map((item) => {
        item.name = this.$t(`message.sectorRoof.roofBase.${item.base}`)

        return item
      })
    }
  }
}
</script>

<style scoped lang="sass">
.roof-options
  &__base
    margin-bottom: rem(28)
    .title
      text-align: left
      font-style: normal
      font-weight: bold
      font-size: rem(18)
      line-height: 140%
      color: $black
      margin-bottom: rem(17)
    .basements
      display: flex
      align-items: center
      +media((align-items: (576: center)))
      +media((flex-direction: (320: column, 576: row)))
      ::v-deep .item
        &:first-child
          .item__name
            +media((border-radius: (320: rem(4) rem(4) 0 0, 576: rem(4) 0 0 rem(4))))
        &:last-child
          .item__name
            +media((border-radius: (320: 0 0 rem(4) rem(4), 576: 0 rem(4) rem(4) 0)))
</style>
