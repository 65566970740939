import { render, staticRenderFns } from "./ratioSwitcher.vue?vue&type=template&id=4f991a63&scoped=true"
import script from "./ratioSwitcher.vue?vue&type=script&lang=js"
export * from "./ratioSwitcher.vue?vue&type=script&lang=js"
import style0 from "./ratioSwitcher.vue?vue&type=style&index=0&id=4f991a63&prod&lang=sass"
import style1 from "./ratioSwitcher.vue?vue&type=style&index=1&id=4f991a63&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f991a63",
  null
  
)

export default component.exports