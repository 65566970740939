<template>
  <div class="base-types">
    <div class="title">{{ $t('message.sectorFastener.baseTypeSwitcher.title') }}</div>
    <div class="basements">
      <basement-type-radio
        v-for="item in baseTypes"
        :item="item"
        :key="item.id"
        id="fastener"
        smaller
        :current-basement="currentBasement"
        @updateBasement="updateBasement"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BasementTypeRadio from '@/components/dump/BasementTypeRadio'
// import { baseTypes } from '@/utils'

export default {
  props: {
    currentBasement: {
      type: Number,
      required: true
    }
  },
  name: 'BaseTypeSwitcher',
  components: { BasementTypeRadio },
  methods: {
    updateBasement(basement) {
      this.$emit('updateBasement', basement)
    }
  },
  computed: {
    ...mapState({
      baseTypes: state => state.baseTypes
    })
    // baseTypes: () => baseTypes
  }
}
</script>

<style scoped lang="sass">
.base-types
  text-align: left
  .title
    @extend %title-mini
    margin-bottom: rem(16)
  .basements
    display: flex
    align-items: center
    .item
      width: rem(94)
      max-width: calc(100% / 3)
    ::v-deep .item
      &:first-child
        .item__name
          border-radius: rem(4) 0 0 rem(4)
      &:last-child
        .item__name
          border-radius: 0 rem(4) rem(4) 0
</style>
