<template>
  <section class="navigation__slider" ref="pane">
    <div class="container navigation__slider-container">
      <nav class="nav">
        <div class="nav__remaining" v-if="remainingSlopes > 0">
          {{ $t('message.nav.again') }} {{ remainingSlopes }}<br />
          {{ word }}
        </div>
        <swiper :options="swiperOption" ref="mySwiper">
          <router-link
            :to="{ name: 'Wizard', params: { id: i }}"
            class="swiper-slide"
            :class="{ disabled: !isResultPageAllowed }"
            v-for="(sector, i) in allSlopes"
            :key="sector.uid"
          >
            <div class="slide">
              <div class="slide__img">
                <component
                  :is="getCurrentFigureComponent(sector.slopeForm)"
                  :navId="i"
                  :navSlopeParams="sector.slopeParams"
                  :defaultSvgParams="defaultSvgParams"
                  :isNav="true"
                >
                </component>
              </div>
              <div class="slide__info">
                <div class="slide__info-number"><span v-if="i + 1 <= 9">0</span>{{ i + 1 }}</div>
                <div class="slide__info-count">x{{ allSlopes[i].slopeCount }}</div>
              </div>
            </div>
          </router-link>
          <div class="swiper-scrollbar" slot="scrollbar"></div>
        </swiper>
        <button
          @click="addFromNav"
          class="nav__save"
          :data-value="$t('message.nav.newArea')"
          :disabled="!isResultPageAllowed"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#ECECEC" />
            <path
              d="M12 18V6M18 12H6"
              stroke="#D6D6D6"
              stroke-width="2"
              stroke-miterlimit="22.9256"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </nav>
    </div>
    <section class="nav__pane-section">
      <div class="container">
        <div class="nav__pane">
          <div class="nav__pane-item" @click="removeSlopeType">
            <div v-if="slopeFromState.slopeType === ''">
              <div class="nav__pane-title" :class="{ active: activeStep('step1') }">
                <span class="nav__pane-title--number">{{
                  $t('message.nav.steps.slopeType.index')
                }}</span>
                <span class="nav__pane-title--text">
                  <i18n :path="`message.nav.steps.slopeType.title`">
                    <template #break>
                      <br />
                    </template>
                  </i18n>
                </span>
              </div>
            </div>
            <div v-else class="nav__pane-step--passed">
              <img src="@/assets/img/nav-tick.svg" alt="ok" />
              <img
                :src="
                  slopeType === 'mainSlope'
                    ? require('@/assets/img/mainSlope-nav.svg')
                    : require('@/assets/img/contrSlope-nav.svg')
                "
                class="nav__pane-step--passed__img"
                :alt="
                  slopeType === 'mainSlope'
                    ? $t('message.nav.mainSlope')
                    : $t('message.nav.contrSlope')
                "
              />
              <div
                v-text="
                  slopeType === 'mainSlope'
                    ? $t('message.nav.mainSlope')
                    : $t('message.nav.contrSlope')
                "
                class="nav__pane-step--passed__title"
              ></div>
            </div>
          </div>
          <div class="nav__pane-item" @click="removeSlopeMaterial">
            <div v-if="slopeFromState.slopeType === '' || slopeFromState.materialSize.width === 0">
              <div
                class="nav__pane-title"
                :class="{
                  active: activeStep('step2')
                }"
              >
                <span class="nav__pane-title--number">{{
                  $t('message.nav.steps.material.index')
                }}</span>
                <span class="nav__pane-title--text">
                  <i18n :path="`message.nav.steps.material.title`">
                    <template #break>
                      <br />
                    </template>
                  </i18n>
                </span>
              </div>
            </div>
            <div v-else class="nav__pane-step--passed">
              <img src="@/assets/img/nav-tick.svg" alt="ok" />
              <img
                :src="currentMaterial.src"
                :alt="currentMaterial.name"
                class="nav__pane-step--passed__material"
              />
              <div class="nav__pane-step--passed__info">
                <div class="nav__pane-step--passed__title">{{ currentMaterial.name }}</div>
                <div class="nav__pane-step--passed__title">
                  <img src="@/assets/img/angle.svg" alt="angle" />
                  <b>{{ currentMaterial.angle }}</b>
                </div>
                <div class="nav__pane-step--passed__title">
                  {{ slopeFromState.materialSize.width }} {{ $t('message.math.multiplication') }}
                  {{ slopeFromState.materialSize.height }}
                  {{ $t('message.units.mm') }}
                </div>
              </div>
            </div>
          </div>
          <div class="nav__pane-item">
            <div
              class="nav__pane-title"
              :class="{
                active: activeStep('step3')
              }"
            >
              <span class="nav__pane-title--number">{{
                $t('message.nav.steps.parametersLayer.index')
              }}</span>
              <div class="nav__pane-step--passed__info">
                <span class="nav__pane-title--text"
                  >{{ $t('message.nav.steps.parametersLayer.title.parameters') }}
                  <i>{{ $t('message.nav.steps.parametersLayer.title.layer') }}</i></span
                >
              </div>
            </div>
          </div>
        </div>
        <button class="btn-result" @click="resultFromNav" :disabled="!isResultPageAllowed">
          <img src="@/assets/img/calculator.svg" alt="calculate" />
        </button>
      </div>
    </section>
  </section>
</template>

<script>
import { isResultPageAllowed, getFormattedWord } from '@/utils'
import { defaultSvgParams, formsComponents } from '@/utils/drawing'
import { calcLabel, sendStatistic, statisticLabels, steps } from '@/utils/sendStatistic'

export default {
  props: {
    slopeType: String
  },
  data() {
    return {
      count: 0,
      swiperOption: {
        slidesPerView: 8,
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: false
        },
        breakpoints: {
          992: {
            slidesPerView: 7
          },
          680: {
            slidesPerView: 5
          },
          520: {
            slidesPerView: 4
          },
          359: {
            slidesPerView: 3
          }
        }
      }
    }
  },
  methods: {
    getCurrentFigureComponent(slopeForm) {
      return formsComponents[slopeForm]
    },
    addFromNav() {
      if (this.isResultPageAllowed) {
        this.$emit('addFromNav')
      }
    },
    resultFromNav() {
      if (this.isResultPageAllowed) {
        this.$router.push({ name: 'Result' })
      }
    },
    removeSlopeMaterial() {
      this.$store.commit('removeSlopeMaterial', this.slopeId)
    },
    removeSlopeType() {
      this.$store.commit('setSlopeToDefault', this.slopeId)
    },
    activeStep(step) {
      switch (step) {
        case steps[0]:
          if (this.slopeFromState.slopeType === '') {
            this.sendStatistic(step)
            return true
          }
          break
        case steps[1]:
          if (
            this.slopeFromState.slopeType !== '' &&
            this.slopeFromState.materialSize.width === 0
          ) {
            this.sendStatistic(step)
            return true
          }
          break
        case steps[2]:
          if (
            this.slopeFromState.slopeType !== '' &&
            this.slopeFromState.materialSize.width !== 0
          ) {
            this.sendStatistic(step)
            return true
          }
          break
      }
      return false
    },
    sendStatistic(step) {
      const stepEvents = window.dataLayer
        .filter(l => l.event ? l.event.includes(`calc-${calcLabel}-step`) || l.event.includes(`calc-${calcLabel}-final`) : false)
        .reverse()

      if (stepEvents.length) {
        const isRepeat = stepEvents[0].event.includes(step)
        const isFromResultPage = stepEvents[0].event.includes('final')

        if (!isRepeat || isFromResultPage) sendStatistic(statisticLabels.steps[step])
      }
    }
  },
  watch: {},
  computed: {
    currentMaterial() {
      return this.allMaterials.find(m => m.id === this.slopeFromState.material)
    },
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    swiper() {
      return this.$refs.mySwiper.swiper
    },
    allMaterials() {
      return this.$store.getters.allMaterials
    },
    remainingSlopes() {
      return this.allSlopes.length - 8
    },
    word() {
      const words = this.$t('message.result.head.words').sectors
      return getFormattedWord(words, this.count)
    },
    defaultSvgParams: () => defaultSvgParams,
    isResultPageAllowed() {
      return isResultPageAllowed(this.allSlopes)
    }
  }
}
</script>

<style lang="sass">
.navigation__slider
  .navigation__slider-container
    padding: 0
.nav
  +media((display: (320: grid, 1170: block)))
  grid-template-columns: 12fr 2fr
  &__save
    background: none
    box-shadow: none
    cursor: pointer
    border: rem(1) dashed $tn_gray
    box-sizing: border-box
    border-radius: rem(4)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: absolute
    top: 50%
    transition: .5s
    overflow: hidden
    outline: none
    +media((position: (320: relative, 992: absolute)))
    +media((right: (320: initial, 1170: rem(-95))))
    +media((top: (320: initial, 1170: 50%)))
    +media((height: (320: rem(86), 992: rem(82))))
    +media((position: (320: relative, 1170: absolute)))
    +media((transform: (320: translateY(0), 1170: translateY(-50%))))
    +media((width: (320: 100%, 1170: rem(85))))
    +media((max-width: (320: rem(40), 400: rem(50), 576: rem(75), 600: rem(80), 700: rem(85))))
    +media((margin: (320: auto, 768: auto auto auto rem(11), 1170: 0)))
    &:after
      content: attr(data-value)
      position: absolute
      height: 100%
      width: 100%
      top: 0
      left: 0
      color: $white
      background: $green
      font-family: 'Montserrat', sans-serif
      font-style: normal
      font-weight: 700
      font-size: rem(12)
      line-height: rem(15)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      transition: .5s
      opacity: 0
      +media((display: (320: none, 992: flex)))
    &:hover
      &:after
        opacity: 1
    svg
      circle, path
        transition: .5s
      circle
        fill: $green
      path
        stroke: $white
    &:disabled
      svg
        circle
          fill: $ec
        path
          stroke: $tn_gray
      cursor: no-drop
      &:hover
        &:after
          opacity: 0
  &__remaining
    font-style: normal
    font-weight: 500
    font-size: rem(12)
    line-height: rem(15)
    color: $main
    position: absolute
    width: rem(105)
    top: 50%
    transform: translateY(-50%)
    left: rem(-105)
    +media((display: (320: none, 1170: block)))
nav
  position: relative
  .swiper
    &-container
      width: auto
      +media((margin: (320: 0, 1170: 0 auto)))
      .swiper-slide
        &.disabled
          pointer-events: none
      &:hover
        .swiper
            &-scrollbar
              &-drag
                background: rgba(227, 7, 19, 0.5)
        > .swiper-scrollbar
          background: rgba(0, 0, 0, 0.1)
    &-slide
      text-decoration: none
      svg
        +media((max-width: (320: 100%, 1170: initial)))
        +media((max-height: (320: rem(57), 768: initial)))
      &:before
        height: 100%
        width: 100%
        content: url(~@/assets/img/cog.svg)
        position: absolute
        top: 0
        left: 0
        pointer-events: none
        background: rgba(51, 51, 51, 0.4)
        border: rem(1) solid $tn_gray
        opacity: 0
        transition: .5s
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        box-sizing: border-box
        z-index: 1
      &:hover
        &:before
          opacity: 1
      &.router-link-active
        .slide__info-number
          color: $black
        &:before
          background: $white
          opacity: 1
          border: none
          border-top: rem(4) solid $main
          border-bottom: rem(4) solid transparent
          box-shadow: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5)
          content: url(~@/assets/img/cog-active.svg)
    &-scrollbar
      height: rem(4)
      top: 0
      &-drag
        border-radius: rem(12)
        background: rgba(227, 7, 19, 0.2)
  .swiper-container-horizontal > .swiper-scrollbar
    background: rgba(0, 0, 0, 0.05)
    height: rem(4)
    width: 100%
    left: 0
  .slide
    display: flex
    flex-direction: column
    align-items: center
    padding: 0 rem(10) rem(10)
    background: rgba(246, 246, 246, 0.15)
    box-shadow: inset rem(-1) 0 0 $tn_gray
    &__img
      padding-top: rem(13)
    &__info
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      width: 100%
      &-number, &-count
        font-style: normal
        font-size: rem(12)
        line-height: 1
        color: $tn_gray_plus
        transition: .5s
      &-count
        font-weight: 500
      &-number
        font-weight: bold
        z-index: 2
.nav
  &__pane
    grid-template-columns: 2fr 3fr 3fr
    +media((display: (320: flex, 1170: grid)))
    +media((margin: (320: 0, 1170: 0 rem(-15))))
    +media((width: (320: 100%, 1170: calc(100% + 30px))))
    &-item
      min-height: rem(102)
      display: flex
      flex-direction: row
      align-items: center
      cursor: pointer
      box-shadow: inset rem(-1) 0 0 $ec
      +media((padding-left: (320: rem(14), 992: 0)))
      +media((padding-right: (320: 0, 992: rem(20), 1170: 0)))
      &:first-of-type
        +media((padding-left: (992: rem(20), 1170: 0)))
      &:nth-child(2), &:nth-child(3)
        +media((padding-left: (320: rem(14), 992: rem(20), 1170: rem(40))))
      &:last-of-type
        box-shadow: none
    &-title
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(18)
      color: $tn_gray_plus
      display: flex
      flex-direction: row
      align-items: center
      br
        +media((display: (320: block, 576: none)))
      &--number
        font-style: normal
        font-weight: 600
        font-size: rem(14)
        line-height: rem(30)
        color: $white
        height: rem(24)
        width: rem(24)
        background: $another-white
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        border-radius: 50%
        +media((margin-right: (320: rem(14), 992: rem(16))))
      &.active
        color: $black
        font-weight: bold
        +media((width: (320: 100% , 992: auto)))
        +media((padding-right: (320: rem(14), 768: none)))
        .nav__pane-title--number
          background: $main
        .nav__pane-title--text
          display: block
      &--text
        text-align: left
        +media((display: (320: none, 992: block)))
        i
          font-style: normal
          +media((display: (320: none, 992: inline-block)))
    &-step
      &--passed
        display: flex
        flex-direction: row
        align-items: center
        +media((padding-right: (320: rem(14), 992: 0)))
        &__title
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: rem(18)
          color: $black
          text-align: left
          +media((display: (320: none, 992: block)))
        &__img
          margin: 0 rem(17)
          +media((display: (320: none, 992: block)))
        &__material
          max-width: rem(80)
          margin: 0 rem(17)
          +media((display: (320: none, 992: block)))
        &__info
          display: flex
          flex-direction: column
          align-items: flex-start
    .slopeName
      &__input
        background: none
        box-shadow: none
        border: none
        box-sizing: border-box
        font-family: $main-font
        font-style: normal
        font-weight: 500
        font-size: rem(12)
        line-height: rem(18)
        color: $black
        outline: none
        +media((padding: (320: rem(6) 0 rem(8), 992: rem(6) rem(27) rem(6) rem(10))))
        +media((width: (320: rem(102), 360: rem(116), 400: rem(150), 576: rem(240),992: rem(200), 1170: rem(240))))
        +media((border: (320: none, 992: rem(1) solid $another-white)))
        +media((box-shadow: (320: inset 0 rem(-2) 0 $ec, 992: none)))
        &-overlay
          position: relative
          &:after
            content: ""
            height: rem(24)
            width: rem(24)
            background: url(~@/assets/img/pen.svg)
            position: absolute
            right: rem(3)
            top: 50%
            transform: translateY(-50%)
    &-section
      background: $white
      box-shadow: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5)
      .container
        position: relative
        grid-template-columns: 11fr 3fr
        +media((display: (320: grid, 1170: flex)))
        +media((padding: (320: 0, 1170: 0 rem(15))))
        +media((grid-template-columns: (320: 12fr 2fr, 360: 11fr 3fr, 500: 12fr 2fr)))
      .btn
        &-result
          top: 0
          height: rem(102)
          background: none
          outline: none
          box-shadow: none
          border: none
          transition: .5s
          background: $main
          cursor: pointer
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          max-width: rem(105)
          +media((position: (320: relative, 1170: absolute)))
          +media((width: (320: 100%, 992: rem(105))))
          +media((right: (320: initial, 1170: rem(-105))))
          &:hover
            background: $main
            opacity: $hover-opacity
          &:disabled
            cursor: no-drop
            background: $ec
            &:hover
              background: $main
              background: $ec
</style>
