<template>
  <div class="roof-options__slope">
    <div class="title">{{ $t('message.sectorRoof.roofSlope.title') }}</div>
    <div class="inputs">
      <input
        type="range"
        :min="min"
        :max="max"
        step="0.1"
        class="similar__box-inputs-range"
        :style="`--min: ${min};--max: ${max};--val: ${slopeParams.roofSlope};`"
        ref="inputRange"
        v-model.number="slopeParams.roofSlope"
        @change="changeRoofSlope($event.target.valueAsNumber)"
        @input="updateSlopeInput"
      />
      <input
        class="info"
        ref="inputSlope"
        @focus="removeSuffix"
        @blur="addSuffix"
        @keypress="checkInput($event)"
        @change="addSuffix"
      />
    </div>
  </div>
</template>

<script>
import { clamp } from '@/utils'
export default {
  props: {
    slope: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data: () => ({
    inputSelector: '.similar__box-inputs-range',
    max: 20,
    min: 1.5
  }),
  name: 'RoofOptionsSlope',
  methods: {
    styleInputRange() {
      document.documentElement.classList.add('js')
      this.updateSlopeInput()
      this.$refs.inputRange.addEventListener(
        this.inputSelector,
        () => {
          this.$refs.inputRange.style.setProperty('--val', +this.$refs.inputRange.value)
        },
        false
      )
    },
    changeRoofSlope(val) {
      if (!isNaN(val)) {
        this.$emit('updateSlope', val)
      }
    },
    removeSuffix() {
      this.$refs.inputSlope.value = this.$refs.inputSlope.value.replace('%', '')
    },
    addSuffix() {
      if (!this.$refs.inputSlope.value.includes('%') && !isNaN(this.inputVal)) {
        this.$refs.inputSlope.value = `${this.inputVal}%`
      }
      this.changeRoofSlope(this.inputVal)
    },
    updateSlopeInput() {
      this.$refs.inputSlope.value = `${this.$refs.inputRange.value}%`
    },
    checkInput(e) {
      if (e.key === 'Enter') {
        this.addSuffix()
        return true
      }
      if (/\d|[.]/.test(e.key)) {
        return true
      } else {
        e.preventDefault()
      }
    }
  },
  mounted() {
    this.styleInputRange()
  },
  computed: {
    slopeParams() {
      const [paramsKit] = this.slope.slopeParams
      return paramsKit
    },
    inputVal: {
      cache: false,
      get() {
        return clamp(parseFloat(this.$refs.inputSlope.value), this.min, this.max)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.roof-options
  &__slope
    display: flex
    flex-direction: column
    align-items: flex-start
    .title
      font-style: normal
      font-weight: bold
      font-size: rem(12)
      line-height: 130%
    .inputs
      display: flex
      align-items: center
      width: 100%
      .info
        display: inline-block;
        position: relative;
        background: $white
        border: rem(1) solid $another-white
        box-sizing: border-box
        border-radius: rem(4)
        width: rem(68)
        height: rem(40)
        text-align: center
        outline: none
        margin: 0 rem(9)
        font-family: $main-font
        font-style: normal
        font-weight: 600
        font-size: rem(18)
        line-height: rem(22)
        color: rgba(28, 28, 27, 0.9)
        transition: .5s
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        +media((margin: (320: 0 0 0 auto, 576: 0 0 0 rem(23))))
</style>
