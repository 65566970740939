<template>
  <div class="item" :class="{ 'item--smaller': smaller }">
    <input
      type="radio"
      :name="`basement-type--${id}`"
      :value="item.id"
      :id="`${id}-base--${item.id}`"
      v-model="computedBasement"
    />
    <label class="item__name" :for="`${id}-base--${item.id}`">{{ item.name }}</label>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
      required: true
    },
    currentBasement: {
      type: Number,
      required: true
    },
    id: {
      type: String,
      default: null,
      required: true
    },
    smaller: {
      type: Boolean
    }
  },
  name: 'BasementTypeRadio',
  computed: {
    computedBasement: {
      get() {
        return this.currentBasement
      },
      set(basement) {
        // console.log(basement)
        this.$emit('updateBasement', basement)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.item
  +media((width: (320: 100%, 576: auto)))
  box-sizing: border-box

  input
    display: none
    position: absolute
    pointer-events: none
    opacity: 0
    &:checked
      + .item__name
          background: $main
          color: $white
          border-color: $main
  &__name
    font-style: normal
    font-weight: 500
    font-size: rem(12)
    line-height: 100%
    color: $black
    cursor: pointer
    border: rem(1) solid $another-white
    +media((padding: (320: rem(13), 768: rem(13) rem(19))))
    display: flex
    transition: .5s
    box-sizing: border-box
    justify-content: center
    @media(any-hover: hover)
      &:hover
        border-color: $main
  &--smaller
    .item
      &__name
        +media((padding: (320: rem(13), 768: rem(13))))
</style>
