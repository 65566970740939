<template>
  <div class="input-box">
    <span :class="classes"> {{ currentShortcut.shortcut }} </span>
    <div class="input-box__content">
      <label class="label label--full" :for="`input--${this.computedParam.key}`">
        {{ currentLabel.full }}
      </label>
      <label class="label label--short" :for="`input--${this.computedParam.key}`">
        {{ currentLabel.short }}
      </label>
      <input
        v-model.number="computedVal"
        class="input"
        :id="`input--${computedParam.key}`"
      />
    </div>
  </div>
</template>

<script>
import { isValidNumber } from '@/utils'
const shortcuts = {
  square: {
    width: {
      shortcut: 'A',
      color: 'green'
    },
    height: {
      shortcut: 'E',
      color: 'blue'
    }
  },
  trapezoid: {
    width: {
      shortcut: 'A',
      color: 'green'
    },
    height: {
      shortcut: 'E',
      color: 'blue'
    },
    bias1: {
      shortcut: 'B',
      color: 'violet'
    },
    bias2: {
      shortcut: 'C',
      color: 'orange'
    }
  },
  reverseTrapezoid: {
    width: {
      shortcut: 'A',
      color: 'green'
    },
    height: {
      shortcut: 'E',
      color: 'blue'
    },
    bias1: {
      shortcut: 'B',
      color: 'violet'
    },
    bias2: {
      shortcut: 'C',
      color: 'orange'
    }
  },
  cornerLeftTop: {
    width: {
      shortcut: 'A',
      color: 'green'
    },
    height: {
      shortcut: 'E',
      color: 'blue'
    },
    biasHeight: {
      shortcut: 'B',
      color: 'violet'
    },
    biasWidth: {
      shortcut: 'C',
      color: 'orange'
    }
  },
  cornerRightTop: {
    width: {
      shortcut: 'A',
      color: 'green'
    },
    height: {
      shortcut: 'E',
      color: 'blue'
    },
    biasHeight: {
      shortcut: 'B',
      color: 'violet'
    },
    biasWidth: {
      shortcut: 'C',
      color: 'orange'
    }
  },
  cornerLeftBottom: {
    width: {
      shortcut: 'A',
      color: 'green'
    },
    height: {
      shortcut: 'E',
      color: 'blue'
    },
    biasHeight: {
      shortcut: 'B',
      color: 'violet'
    },
    biasWidth: {
      shortcut: 'C',
      color: 'orange'
    }
  },
  cornerRightBottom: {
    width: {
      shortcut: 'A',
      color: 'green'
    },
    height: {
      shortcut: 'E',
      color: 'blue'
    },
    biasHeight: {
      shortcut: 'B',
      color: 'violet'
    },
    biasWidth: {
      shortcut: 'C',
      color: 'orange'
    }
  },
  triangle: {
    width: {
      shortcut: 'B',
      color: 'violet'
    },
    offsetValley: {
      shortcut: 'E',
      color: 'blue'
    }
  },
  doubleSideTriangleHorizontal: {
    width: {
      shortcut: 'B',
      color: 'violet'
    }
  },
  doubleSideTriangleVertical: {
    width: {
      shortcut: 'D',
      color: 'yellow'
    },
    offsetValley: {
      shortcut: 'E',
      color: 'blue'
    }
  },
  rhombus: {
    width: {
      shortcut: 'D',
      color: 'yellow'
    }
  },
  rectangularTrapezoidVertical: {
    width: {
      shortcut: 'B',
      color: 'violet'
    },
    bias1: {
      shortcut: 'C',
      color: 'orange'
    },
    offsetValley: {
      shortcut: 'E',
      color: 'blue'
    }
  },
  rectangularTrapezoidHorizontal: {
    width: {
      shortcut: 'B',
      color: 'violet'
    },
    bias1: {
      shortcut: 'C',
      color: 'orange'
    },
    offsetValley: {
      shortcut: 'E',
      color: 'blue'
    }
  },
  doubleSideRectangularTrapezoidVertical: {
    width: {
      shortcut: 'D',
      color: 'yellow'
    },
    bias1: {
      shortcut: 'C',
      color: 'orange'
    },
    offsetValley: {
      shortcut: 'E',
      color: 'blue'
    }
  }
}

export default {
  props: {
    param: {
      type: Array,
      default: () => [],
      required: true
    },
    form: {
      type: String,
      default: null,
      required: true
    },
    canBeZero: {
      type: Boolean
    }
  },
  name: 'paramInput',
  computed: {
    currentShortcut() {
      return shortcuts[this.form][this.computedParam.key]
    },
    currentLabel() {
      return this.labels[this.computedParam.key]
    },
    labels() {
      return this.$t('message.labels')
    },
    computedParam() {
      const [key, value] = this.param
      return { key, value }
    },
    classes() {
      return ['input-box__shortcut', `input-box__shortcut--${this.currentShortcut.color}`]
    },
    computedVal: {
      get() {
        return this.computedParam.value
      },
      set(val) {
        if (isValidNumber(val, this.canBeZero)) {
          this.$emit('updateParam', { key: this.computedParam.key, val })
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.input-box
  width: 100%
  +media((max-width: (768: rem(240))))
  display: flex
  align-items: center
  +media((min-height: (320: rem(40), 768: rem(50))))
  &__shortcut
    background: $another-white
    +media((width: (320: rem(16), 768: rem(20))))
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    font-style: normal
    font-weight: bold
    font-size: rem(12)
    line-height: 100%
    color: $white
    +media((flex: (320: 0 0 rem(16), 768: 0 0 rem(20))))
    border-radius: rem(4) 0 0 rem(4)
    +media((min-height: (320: rem(42), 768: rem(52))))
    &--green
      background: $figure-green
    &--blue
      background: $figure-blue
    &--violet
      background: $figure-violet
    &--orange
      background: $figure-orange
    &--yellow
      background: $figure-yellow
  &__content
    display: flex
    align-items: center
    width: calc(100% - 20px)
    height: 100%
    border-radius: 0 rem(4) rem(4) 0
    overflow: hidden
    border: rem(1) solid $another-white
    border-left: none
    min-height: inherit
    .label
      font-style: normal
      font-weight: 500
      +media((font-size: (320: rem(12), 768: rem(14))))
      line-height: 100%
      height: 100%
      align-items: center
      color: $light_black
      +media((padding-left: (320: rem(10), 768: rem(20))))
      width: 100%
      // +media((max-width: (320: rem(60), 768: rem(112))))
      text-align: left
      &--short
        +media((display: (320: flex, 768: none)))
      &--full
        +media((display: (320: none, 768: flex)))
    .input
      +media((width: (320: rem(40), 768: rem(68))))
      min-height: inherit
      font-family: $main-font
      padding: 0 rem(5)
      text-overflow: ellipsis
      margin: 0 0 0 auto
      border: none
      height: 100%
      font-style: normal
      font-weight: 600
      +media((font-size: (320: rem(16), 768: rem(18))))
      +media((line-height: (320: 100%, 768: rem(22))))
      color: rgba(#1C1C1B, .9)
      text-align: center
      outline: none
</style>
