<template>
  <div class="roof-options">
    <roof-options-base :roof-options="slope.roofOptions" @updateBasement="updateBasement" />
    <roof-options-slope
      :slope="slope"
      @updateSlope="updateSlope"
      v-if="slope.roofOptions.base === 'default'"
    />
    <roof-options-material :slope="slope" v-else @updateMaterial="updateMaterial" />
  </div>
</template>

<script>
import RoofOptionsBase from '@/components/dump/sector_roof_options/RoofOptionsBase'
import RoofOptionsSlope from '@/components/dump/sector_roof_options/RoofOptionsSlope'
import RoofOptionsMaterial from '@/components/dump/sector_roof_options/RoofOptionsMaterial'
export default {
  components: { RoofOptionsMaterial, RoofOptionsSlope, RoofOptionsBase },
  props: {
    slope: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  name: 'RoofOptions',
  methods: {
    updateBasement(basement) {
      this.$emit('updateRoofBasement', basement)
    },
    updateSlope(roofSlope) {
      this.$emit('updateRoofSlope', roofSlope)
    },
    updateMaterial(material) {
      this.$emit('updateRoofMaterial', material)
    }
  }
}
</script>

<style scoped lang="sass">
.roof-options
  //+media((padding: (320: rem(34) rem(25), 992: rem(34) rem(40) rem(46))))
  +media((padding: (320: 0 rem(25) rem(57), 992: 0 rem(40) rem(57))))
  background: $white
  // margin: 0 rem(-15) rem(17)
  margin: 0 rem(-15)
  color: $black
</style>
