<template>
  <div class="select-box">
    <div class="select-box__title">
      <i18n path="message.controls.switcher.ratio">
        <template #break>
          <br />
        </template>
      </i18n>
      <span class="side">{{ $t('message.controls.switcher.sideA') }}</span> /
      <span class="side" :style="{ background: sideBColor }">{{
        $t('message.controls.switcher.sideB')
      }}</span>
    </div>
    <div class="select-box__select">
      <select v-model.number="computedRatio" class="select__box-select">
        <option :value="ratio.value" v-for="ratio in ratios" :key="ratio.id">
          {{ ratio.text }}
        </option>
      </select>
      <span class="caret" v-html="getIcon('caret')"></span>
    </div>
    <tippy
      interactive
      placement="right"
      maxWidth="400"
      theme="light"
      animation="shift-toward"
      arrow="false"
    >
      <template #trigger>
        <div class="select-box__tooltip">
          <span v-html="getIcon('tooltip')"></span>
        </div>
      </template>
      <span class="tooltip__body">
        <span class="tooltip__body-text"> {{ $t('message.controls.switcher.define') }} </span>
        <a class="tooltip__body-link" :href="$t('message.links.ratioSwitcher')" target="_blank">
          <span class="icon" v-html="getIcon('tooltipLink')"></span>
          {{ $t('message.general.site') }}
        </a>
      </span>
    </tippy>
  </div>
</template>

<script>
import { colors } from '@/utils/drawing'
import { ratios } from '@/utils'
import IconMixin from '@/components/mixin/IconMixin'

const figuresWithVioletSideB = [
  'triangle',
  'doubleSideTriangleHorizontal',
  'rectangularTrapezoidVertical',
  'rectangularTrapezoidHorizontal'
]

export default {
  mixins: [IconMixin],
  props: {
    form: {
      type: String,
      default: null,
      required: true
    },
    ratio: {
      type: Number,
      required: true
    }
  },
  name: 'ratioSwitcher',
  computed: {
    ratios() {
      return ratios.map(item => {
        item.text = item.text.replace('к', this.$t('message.controls.switcher.ratioPreposition'))

        return item
      })
    },
    sideBColor() {
      return figuresWithVioletSideB.includes(this.form) ? colors.violet : colors.grey
    },
    computedRatio: {
      get() {
        return this.ratio
      },
      set(val) {
        this.$emit('updateRatio', val)
      }
    }
  }
}
</script>
<style lang="sass">
.tippy-tooltip.light-theme
  background: $white
  box-shadow: 0 rem(1) rem(4) rgba(51, 51, 51, 0.3), 0 rem(5) rem(30) rgba(51, 51, 51, 0.2)
  border-radius: rem(4)
  padding: 0
  box-sizing: border-box
  max-width: rem(400)
.tooltip__body
  padding: rem(50) rem(60)
  display: flex
  flex-direction: column
  align-items: flex-start
  &-text
    text-align: left
    color: $black
    font-style: normal
    font-weight: normal
    font-size: rem(14)
    line-height: 150%
    margin-bottom: rem(24)
  &-link
    display: flex
    align-items: center
    font-style: normal
    font-weight: 500
    font-size: rem(12)
    line-height: 150%
    text-decoration-line: underline
    color: $main
    .icon
      line-height: 0
      margin-right: rem(15)
</style>
<style scoped lang="sass">
@import '@/assets/sass/inline-sector.sass'
.select
  &-box
    display: flex
    align-items: center
    margin-top: rem(10)
    +media((grid-column: (320: '1 / 3', 768: '1 / 2')))
    &__title
      @include text
      width: rem(100)
      .side
        height: rem(18)
        width: rem(18)
        border-radius: 50%
        display: inline-flex
        justify-content: center
        align-items: center
        background: $tn_gray_plus
        color: $white
        font-style: normal
        font-weight: bold
        font-size: rem(12)
        line-height: 100%
    .caret
      display: flex
      align-items: center
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: rem(12)
      height: rem(12)
      width: rem(12)
      pointer-events: none
    &__select
      position: relative
      margin-right: rem(10)
      select
        background: $white
        border: rem(1) solid $tn_gray
        box-sizing: border-box
        border-radius: rem(4)
        height: rem(40)
        width: rem(97)
        outline: none
        box-shadow: none
        font-family: 'Montserrat', sans-serif
        font-style: normal
        font-weight: 600
        font-size: rem(12)
        line-height: rem(15)
        color: $tn_gray_plus
        padding-left: rem(13)
        -webkit-appearance: none
        -moz-appearance: none
        cursor: pointer
    &__tooltip
      @include title-tooltip
</style>
