<template>
  <div>
    <div class="similar__wrapper">
      <sector-counts-controls :count="slope.slopeCount" @updateCount="updateSlopesCount">
        {{ $t('message.wizard.similar.inputs') }} {{ similarSlopeWord }}
      </sector-counts-controls>
      <layer-counter :slope="slope" v-if="showLayers"></layer-counter>
    </div>
    <sector-fasteners
      :fastener-calculation-need="slope.fixtureCalculation.need"
      @updateFastenerCalculationNeed="updateFastenerCalculationNeed"
      :current-basement="slope.fixtureCalculation.type"
      @updateBasement="updateBasement"
      :thickness="slope.fixtureCalculation.thickness"
      @updateInsulationThickness="updateInsulationThickness"
      :current-fastener="slope.fixtureCalculation.fastener"
      @updateFastener="updateCurrentFastener"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import SectorFasteners from '@/components/dump/sector_fasteners'
import LayerCounter from '@/components/figure_controls/layerCounter'
import SectorCountsControls from '@/components/dump/SectorCountsControls'
export default {
  props: {
    slope: Object,
    showLayers: Boolean
  },
  data() {
    return {}
  },
  components: { SectorCountsControls, SectorFasteners, LayerCounter },
  methods: {
    ...mapMutations({
      updateFixtureCalculation: 'updateFixtureCalculation',
      updateSlopeCount: 'updateSlopeCount',
      updateFixtureCalculationThickness: 'updateFixtureCalculationThickness',
      updateSlopeLayersCount: 'updateSlopeLayersCount',
      // updateFixtureCalculationType: 'updateFixtureCalculationType',
      UPDATE_FASTENER_CALCULATION_PARAM: 'UPDATE_FASTENER_CALCULATION_PARAM'
    }),
    ...mapActions({
      updateBasementAndFastener: 'updateBasementAndFastener'
    }),
    updateFastenerCalculationNeed(val) {
      this.updateFixtureCalculation({
        id: this.slopeId,
        need: val,
        slopeType: this.slope.slopeType
      })
    },
    updateBasement(baseTypeId) {
      this.updateBasementAndFastener({
        id: this.slopeId,
        baseTypeId
      })
    },
    updateInsulationThickness(val) {
      this.updateFixtureCalculationThickness({
        id: this.slopeId,
        val
      })
    },
    updateCurrentFastener(val) {
      this.UPDATE_FASTENER_CALCULATION_PARAM({
        id: this.slopeId,
        key: 'fastener',
        val
      })
    },
    updateSlopesCount(count) {
      this.updateSlopeCount({
        id: this.slopeId,
        count
      })
    }
  },
  mounted() {
    // this.pickedMaterial = this.allMaterials[0];
  },
  computed: {
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    defaultSvgParams() {
      return this.$store.getters.getDefaultSvgParams
    },
    similarSlopeWord() {
      return this.$t('message.slopeTypesWords')[this.slopeFromState.slopeType]
    }
  }
}
</script>
<style lang="sass" scoped>
.similar
  &__wrapper
    margin: rem(17) rem(-15)
    background: $white
    .title__tooltip
      line-height: 0
      cursor: help
</style>
<style lang="sass">
.slopePage
  .slopeForm
    // +media((margin-bottom: (320: rem(16), 768: rem(34))))
    &__box
      display: flex
      flex-direction: row
      align-items: center
      overflow-x: auto
      overflow-y: hidden
      // +media((padding-left: (320: 0, 992: rem(20))))
      // +media((padding-right: (320: 0, 992: rem(20))))
      +media((padding: (320: 0, 992: 0 rem(40))))
      position: relative
      +media((border-left: (320: rem(24) solid $white, 992: 0)))
      +media((border-right: (320: rem(24) solid $white, 992: 0)))
      &-item
        line-height: 0
        box-shadow: inset 0 rem(-1) 0 $tn_gray
        @media(any-hover: hover)
          &:hover
            .slopeForm__box-item__btn
              .img
                &--forward
                  left: 0
                  top: 0
                  position: absolute
                  opacity: 0
                  pointer-events: none
                &--backward
                  position: relative
                  opacity: 1
                  pointer-events: auto
        &:not(:last-child)
          padding-right: rem(6)
        // +media((margin-left: (320: 0, 992: rem(20))))
        // +media((margin-right: (320: rem(30), 992: rem(20))))
        &__btn
          display: flex
          // filter: grayscale(1)
          // opacity: .2
          transition: .5s
          cursor: pointer
          position: relative
          &__input
            opacity: 0
            position: absolute
            display: none
            pointer-events: none
          // img
            width: rem(68)
          // &:hover
            opacity: .5
            filter: grayscale(.5)
          .img
            transition: .5s
            min-width: rem(120)
            &--backward
              left: 0
              top: 0
              position: absolute
              opacity: 0
              pointer-events: none
          &--active
            box-shadow: inset 0 rem(-4) 0 $main
            .img
              &--forward
                left: 0
                top: 0
                position: absolute
                opacity: 0
                pointer-events: none
              &--backward
                position: relative
                opacity: 1
                pointer-events: auto
            // filter: grayscale(0)
            // opacity: 1
            // &:hover
              filter: grayscale(0)
              opacity: 1
</style>
