<template>
  <section class="slopePage">
    <div class="container">
      <div
        class="row align-items-center justify-content-between flex-nowrap slopePage__title-wrapper"
      >
        <div class="slopePage__title slopePage__title-withBtn">
          {{ $t('message.wizard.mainSlope.title') }}
        </div>
        <button
          @click="showDeleteConfirmationModal()"
          class="delete__btn"
          v-if="this.allSlopes.length !== 1"
        >
          <svg
            width="19"
            height="25"
            viewBox="0 0 19 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 2.91667L18 5.29333M5.73105 3.60667L7.89615 1L12.1461 1.61333L13.349
            4.60333M12.467 12.1933L12.2264 19.5533M6.61321 12.1933L6.85377 19.63M1.56128
            7.74667H17.4386L16.6368 21.7C16.5566 22.9267 15.5141 24 14.2311 24H4.76883C3.40562 24
            2.44336 22.9267 2.36317 21.7L1.56128 7.74667Z"
              stroke="#999999"
              stroke-width="2"
              stroke-miterlimit="22.9256"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </div>
      <sector-name-changer :name="slopeFromState.name" @updateName="updateSectorName" />
      <div class="row slopeForm slopePage__pane">
        <div class="col-12">
          <div class="slopePage__subtitle">{{ $t('message.wizard.mainSlope.shape') }}</div>
        </div>
        <div class="col-12 slopeForm__box">
          <div v-for="item in slopeForms" :key="item.id" class="slopeForm__box-item">
            <label
              class="slopeForm__box-item__btn"
              :for="item.value"
              :class="{
                'slopeForm__box-item__btn--active': slopeFromState.slopeForm === item.value
              }"
            >
              <input
                type="radio"
                :id="item.value"
                :value="item.value"
                v-model="slopeFromState.slopeForm"
                class="slopeForm__box-item__btn__input"
                @change="updateSlopeForm"
              />
              <img :src="item.src" :alt="item.value" width="100%" class="img img--forward" />
              <img :src="item.srcActive" :alt="item.value" width="100%" class="img img--backward" />
            </label>
          </div>
        </div>
      </div>
      <div class="figure__box slopePage__pane">
        <div class="slopePage__subtitle slopePage__subtitle-figure">{{ $t('message.wizard.contrSlope.size') }}</div>
        <div class="slopePage__inputs-figure">
          <param-input
            v-for="(param, i) in Object.entries(slopeFromState.slopeParams[0])"
            :form="slopeFromState.slopeForm"
            :canBeZero="['bias1', 'bias2', 'biasHeight', 'biasWidth'].includes(param[0])"
            :param="param"
            :key="`param--${i}`"
            @updateParam="updateSlopeParam"
          />
        </div>
        <div class="slopePage__figure-figure">
          <component
            :is="currentFigureComponent"
            :slopeParams="slopeFromState.slopeParams"
            :defaultSvgParams="defaultSvgParams"
          >
          </component>
        </div>
      </div>
      <SimilarInputs :slope="slope" show-layers />
    </div>
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
import ErrorComponent from '../views/404.vue'
import SimilarInputs from './SimilarInputs.vue'
import ParamInput from '@/components/figure_controls/paramInput'
import { defaultSvgParams } from '@/utils/drawing'
import SectorNameChanger from '@/components/dump/SectorNameChanger'
const forms = [
  {
    id: 0,
    value: 'square',
    src: require('@/assets/img/forms/main/square.png'),
    srcActive: require('@/assets/img/forms/main/square--active.png'),
    component: () => import('@/components/figures/Square')
  },
  {
    id: 1,
    value: 'trapezoid',
    src: require('@/assets/img/forms/main/trapezoid.png'),
    srcActive: require('@/assets/img/forms/main/trapezoid--active.png'),
    component: () => import('@/components/figures/Trapezoid')
  },
  {
    id: 2,
    value: 'reverseTrapezoid',
    src: require('@/assets/img/forms/main/reverseTrapezoid.png'),
    srcActive: require('@/assets/img/forms/main/reverseTrapezoid--active.png'),
    component: () => import('@/components/figures/ReverseTrapezoid')
  },
  {
    id: 3,
    value: 'cornerLeftTop',
    src: require('@/assets/img/forms/main/cornerLeftTop.png'),
    srcActive: require('@/assets/img/forms/main/cornerLeftTop--active.png'),
    component: () => import('@/components/figures/CornerLeftTop')
  },
  {
    id: 4,
    value: 'cornerRightTop',
    src: require('@/assets/img/forms/main/cornerRightTop.png'),
    srcActive: require('@/assets/img/forms/main/cornerRightTop--active.png'),
    component: () => import('@/components/figures/CornerRightTop')
  },
  {
    id: 5,
    value: 'cornerLeftBottom',
    src: require('@/assets/img/forms/main/cornerLeftBottom.png'),
    srcActive: require('@/assets/img/forms/main/cornerLeftBottom--active.png'),
    component: () => import('@/components/figures/CornerLeftBottom')
  },
  {
    id: 6,
    value: 'cornerRightBottom',
    src: require('@/assets/img/forms/main/cornerRightBottom.png'),
    srcActive: require('@/assets/img/forms/main/cornerRightBottom--active.png'),
    component: () => import('@/components/figures/CornerRightBottom')
  }
]

export default {
  props: {
    slope: Object
  },
  data() {
    return {}
  },
  components: {
    SectorNameChanger,
    ParamInput,
    ErrorComponent,
    SimilarInputs
  },
  methods: {
    ...mapMutations({
      UPDATE_SLOPE_PARAM: 'UPDATE_SLOPE_PARAM',
      updateSlopeName: 'updateSlopeName'
    }),
    updateSlopeParam(param) {
      const { key, val } = param
      this.UPDATE_SLOPE_PARAM({
        id: this.slopeId,
        key,
        val
      })
    },
    updateSlopeForm() {
      this.$emit('updateSlopeForm', { id: this.slopeId, form: this.slopeFromState.slopeForm })
    },
    updateSlopeWidth(e) {
      if (!isNaN(e.target.valueAsNumber)) {
        this.$store.commit('updateSlopeWidth', { id: this.slopeId, width: e.target.valueAsNumber })
      }
    },
    updateSectorName(name) {
      this.updateSlopeName({
        id: this.slopeId,
        name
      })
    },
    showDeleteConfirmationModal() {
      this.$emit('showDeleteConfirmationModal')
    }
  },
  watch: {},
  async mounted() {
    // this.$store.dispatch('fetchPosts');
    // (this.$store.getters.isErrorLink) ? (this.slopeType = 'ErrorComponent',
    // this.isStratWindow = !this.isStratWindow) : this.slopeType = '';
  },
  computed: {
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    defaultSvgParams: () => defaultSvgParams,
    slopeForms: () => forms,
    currentFigureComponent() {
      return forms.find(f => f.value === this.slopeFromState.slopeForm).component
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
.slopePage
  color: $black
  .slopeForm
    padding: 0 0 rem(57)
  &__pane
    background: $white
    padding: rem(34) 0
    &--contr
      padding-bottom: rem(40)
  &__title
    font-style: normal
    font-weight: 500
    text-align: left
    +media((font-size: (320: rem(18), 992: rem(28))))
    +media((line-height: (320: rem(26), 992: rem(34))))
    +media((padding-left: (320: rem(24), 992: 0)))
    +media((padding-top: (320: rem(32), 992: rem(51))))
    +media((padding-bottom: (320: rem(22), 992: rem(51))))
    +media((padding-right: (320: rem(24), 992: 0)))
    &-wrapper
      .delete
        &__btn
          +media((margin-right: (320: rem(24), 992: 0)))
    &-withBtn
      +media((width: (320: calc(100% - 50px), 768: auto)))
  &__subtitle
    text-align: left
    font-style: normal
    font-weight: bold
    +media((font-size: (320: rem(14), 992: rem(18))))
    +media((line-height: (320: rem(17), 992: rem(22))))
    // +media((margin-bottom: (320: rem(23), 992: rem(34))))
    margin-bottom: rem(17)
    +media((padding-left: (320: rem(9), 992: rem(25))))
    &-figure
      +media((padding-left: (320: rem(25), 768: 0)))
      margin-bottom: rem(34)
  &__inputs

    &-figure
      display: grid
      +media((grid-template-columns: (320: 1fr 1fr, 768: 1fr)))
      +media((width: (320: 80vw, 768: 100%)))
      +media((gap: (320: rem(8), 768: rem(10))))
      // +media((padding-bottom: (320: rem(22), 768: 0)))
      // +media((padding-left: (768: rem(24), 992: rem(15))))
      +media((margin: (320: 0 auto, 768: 0)))
  &__figure
    &-figure
      +media((grid-area: (320: '2 / 1 / 3 / 2', 768: '1 / 2 / 3 / 3')))
      +media((margin-bottom: (320: rem(24), 768: 0)))
  .figure
    &__box
      margin: 0 rem(-15)
      +media((padding: (320: 0 0 rem(32), 768: 0 rem(25) rem(51), 992: 0 rem(40) rem(51))))
      display: grid
      +media((grid-template-columns: (320: 1fr, 768: rem(260) 1fr, 960: 7fr 15fr)))
      +media((grid-template-rows: (768: repeat(auto-fill, rem(22)))))
      +media((row-gap: (768: rem(40))))
      align-items: flex-start
      &-right
        padding: 0
  .figure
    &__wrapper
      width: 100%
      svg
        width: 100%
        height: auto
// .fading-enter-active, .fading-leave-active
//   transition: .3s
//
// .fading-enter, .fading-leave-to
//   filter: blur(10px)
</style>
